import { OptionsClass } from "@/domain/entities/MainApp";

export class RtsHQPenalty {
    crpId = 0;
    crpPercentage = "";
    crpRateType = new OptionsClass();

    constructor(fields?: Partial<RtsHQPenalty>) {
        Object.assign(this, fields);
    }
}

export class DetailPriceRtsHQ {
    configurablePriceId = 0;
    configurablePriceName = "";
    configurablePriceType = "";
    configurablePriceDescription = "";
    configurablePriceStatus = "";
    configurablePriceCreatedAt = "";
    configurablePriceCreatedBy = "";
    configurablePriceUpdatedAt = "";
    configurablePriceUpdatedBy = "";
    configurableRtshqPenalty = new RtsHQPenalty();
  
    constructor(fields?: Partial<DetailPriceRtsHQ>) {
      Object.assign(this, fields);
    }
}