
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";
import ForwardBookingCommissionDetailSectionGeneralConfig from "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-detail/forward-booking-commission-detail-section-general-config.vue";
import { ForwardBookingCommissionController } from "@/app/ui/controllers/ForwardBookingCommissionController";
import ForwardBookingCommissionDetailSectionCommission from "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-detail/forward-booking-commission-detail-section-commission.vue";
import ForwardBookingCommissionDetailSectionAppliedTo from "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-detail/forward-booking-commission-detail-section-applied-to.vue";

@Options({
  components: {
    ForwardBookingCommissionDetailSectionAppliedTo,
    ForwardBookingCommissionDetailSectionCommission,
    ForwardBookingCommissionDetailSectionGeneralConfig,
    DetailLayout,
    CreateUpdate
  }
})
export default class DetailHeavyweightSurcharge extends Vue {
  get isAbleToEdit() {
    return checkRolePermission(PRICING_CONFIGURABLE_PRICE.EDIT);
  }

  get detailData() {
    return ForwardBookingCommissionController.forwardBookingCommissionDetail;
  }

  // route navigatiion
  goBack() {
    this.$router.push("/tarif/configurable-price");
  }
  goEdit() {
    this.$router.push(
      `/tarif/configurable-price/forward-booking-commission/${this.$route.params.id}/edit`
    );
  }
}
