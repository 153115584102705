import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { RefundConfigurationApiRequest } from "@/data/payload/api/RefundConfigurationApiRequest";
import { container } from "tsyringe";
import { RefundConfigurationPresenter } from "@/app/ui/presenters/RefundConfigurationPresenter";
import { RefundConfigurationData } from "@/domain/entities/RefundConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface RefundConfigurationState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "refund-configuration"
})
class RefundConfigurationStore extends VuexModule
  implements RefundConfigurationState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public refundConfigurationDetail = new RefundConfigurationData();

  @Action
  public async onEditRefundConfiguration(params: {
    configPriceId: number;
    configPriceDescription: string;
    configPriceStatus: string;
    configurableRefund: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(RefundConfigurationPresenter);
    return presenter
      .editRefundConfiguration(
        new RefundConfigurationApiRequest(
          params.configPriceId,
          params.configPriceDescription,
          params.configPriceStatus,
          params.configurableRefund
        )
      )
      .then((res: ResponsePayload) => {
        return {
          success: res.success,
          error: ""
        };
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            error,
            "Perubahan Refund Configuration Gagal!",
            () => this.onEditRefundConfiguration(params)
          )
        );
        return {
          success: false,
          error: ""
        };
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getDetailData(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(RefundConfigurationPresenter);
    presenter
      .getDetailRefundConfiguration(id)
      .then(res => {
        this.setRefundConfigurationDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setRefundConfigurationDetail(val: RefundConfigurationData) {
    this.refundConfigurationDetail = val;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }
}

export const RefundConfigurationController = getModule(
  RefundConfigurationStore
);
