
import { Options, Vue } from "vue-class-component";
import { ConfigPriceWoodpackingController } from "@/app/ui/controllers/ConfigPriceWoodpackingController";
import {
  dateToDateString,
  dateToTime,
  formatDate,
  formatPriceIDR
} from "@/app/infrastructures/misc/Utils";

@Options({
  components: {}
})
export default class DetailWoodpacking extends Vue {
  created() {
    this.getDataDetail();
  }
  get isLoading() {
    return ConfigPriceWoodpackingController.isLoading;
  }

  // data detail
  getDataDetail() {
    ConfigPriceWoodpackingController.getDetailWoopacking({
      id: Number(this.$route.params.id)
    });
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  additionalCost = "";
  modelDialog = false;

  get dataDetail() {
    const detail = ConfigPriceWoodpackingController.configPriceWoodpackingData;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updateBy = detail.updatedBy;
    this.additionalCost = formatPriceIDR(detail.additionalCost);
    return detail;
  }

  get mappingType() {
    if (this.dataDetail.type === "dimension") return "Per Dimensi";
    if (this.dataDetail.type === "volume-weight") return "Per Berat Volume";
    return this.dataDetail.type;
  }

  get isError() {
    return ConfigPriceWoodpackingController.isError;
  }

  get errorCause() {
    return ConfigPriceWoodpackingController.errorCause;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  goBack() {
    this.$router.push("/tarif/configurable-price");
  }

  goEdit() {
    this.$router.push(
      `/tarif/configurable-price/woodpacking/${this.$route.params.id}/edit`
    );
  }
}
