
import { Vue, Options } from "vue-class-component";
import { ForwardBookingCommissionController } from "@/app/ui/controllers/ForwardBookingCommissionController";
import Chips from "@/app/ui/components/chips/index.vue";
import startCase from "lodash/startCase";

@Options({
  components: {
    Chips
  }
})
export default class ForwardBookingCommissionDetailSectionCommission extends Vue {
  get detailData() {
    return ForwardBookingCommissionController.forwardBookingCommissionDetail;
  }
  get configForwardCommission() {
    return this.detailData.configurablePriceForwardBookingCommission;
  }
  get commission() {
    return `${this.configForwardCommission.commission}%`;
  }
  titleCase(val: string) {
    return startCase(val);
  }
  convertCommissionParameter(val: string) {
    const parameters = val.split(",").map((item: string) => startCase(item));
    return parameters.join(", ");
  }
}
