import { ConfigPriceRtsHQPresenter } from "@/app/ui/presenters/ConfigPriceRtsHQPresenter";
import { ConfigPriceRtsHQMapper } from "@/data/persistences/mappers/ConfigPriceRtsHQMapper";
import { container } from "tsyringe";
import { ConfigPriceRtsHQEndpoint } from "../../endpoints/horde/ConfigPriceRtsHQEndpoint";
import { ConfigPriceRtsHQApiRepository } from "../../repositories/api/ConfigPriceRtsHQApiRepository";
import ApiService from "../../services/ApiService";

export class ConfigPriceRtsHQComponent {
  public static init() {
    container.register<ConfigPriceRtsHQApiRepository>(ConfigPriceRtsHQApiRepository, {
      useFactory: d => {
        return new ConfigPriceRtsHQApiRepository(
          d.resolve(ApiService),
          d.resolve(ConfigPriceRtsHQMapper),
          d.resolve(ConfigPriceRtsHQEndpoint)
        );
      }
    });
    container.register<ConfigPriceRtsHQMapper>(ConfigPriceRtsHQMapper, {
      useClass: ConfigPriceRtsHQMapper
    });
    container.register<ConfigPriceRtsHQPresenter>(ConfigPriceRtsHQPresenter, {
      useFactory: d => {
        return new ConfigPriceRtsHQPresenter(d.resolve(ConfigPriceRtsHQApiRepository));
      }
    });
  }
}
