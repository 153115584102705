import { ConfigPriceRtsHQComponent } from "@/app/infrastructures/dependencies/modules/ConfigPriceRtsHQComponent";
import store from "@/store";
import { container } from "tsyringe";
import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import { ConfigPriceRtsHQPresenter } from "../presenters/ConfigPriceRtsHQPresenter";
import { SubmitConfigPriceRtsHQApiRequest } from "@/data/payload/api/ConfigPriceRtsHQApiRequest";
ConfigPriceRtsHQComponent.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "config-price-rtshq"
})
class ConfigPriceRtsHQStore extends VuexModule {
    @Action
    public getDetailConfigPriceRtsHQ(id: number) {
        const presenter = container.resolve(ConfigPriceRtsHQPresenter);
        return presenter.getDetailConfigPriceRtsHQ(id);
    }
    
    @Action
    public async editConfigPriceRtsHQ(
      payload: SubmitConfigPriceRtsHQApiRequest
    ) {
      const presenter = container.resolve(ConfigPriceRtsHQPresenter);
      return presenter.editConfigPriceRtsHQ(payload);
    }
}
export const ConfigPriceRtsHQController = getModule(ConfigPriceRtsHQStore);
