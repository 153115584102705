
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import { formatDate } from "@/app/infrastructures/misc/Utils";
import { AdjustmentPenaltyController } from "@/app/ui/controllers/AdjustmentPenaltyController";

@Options({
  components: {
    DetailLayout,
    CreateUpdate,
    Book
  },
  computed: {
    getTitle() {
      return `${this.detailData.configurablePriceName}`;
    }
  }
})
export default class Detail extends Vue {
  // Get Data
  mounted() {
    this.getDetailAdjustment();
  }

  getDetailAdjustment() {
    AdjustmentPenaltyController.getDetailAdjustmentPenalty({
      id: Number(this.$route.params.id)
    });
  }

  get detailData() {
    return AdjustmentPenaltyController.adjustmentPenaltyDetail;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get configurableId() {
    return String(
      this.detailData.configurableSttAdjustment.sttAdjustmentConfigurableId
    );
  }

  // route navigation
  goBack() {
    this.$router.push(`/tarif/configurable-price`);
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.configurablePriceCreatedAt);
  }

  get convertUpdatedAt() {
    return formatDate(this.detailData.configurablePriceUpdatedAt);
  }

  get isLoading() {
    return AdjustmentPenaltyController.isLoading;
  }
  get isError() {
    return AdjustmentPenaltyController.isError;
  }
  get errorCause() {
    return AdjustmentPenaltyController.isErrorCause;
  }

  goToEdit() {
    this.$router.push(
      `/tarif/configurable-price/adjustment-penalty/${this.$route.params.id}/edit`
    );
  }
}
