/* eslint-disable @typescript-eslint/camelcase */
import { DetailForwardBookingCommission } from "@/domain/entities/ForwardBookingCommission";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { ForwardBookingCommissionPresenter } from "../presenters/ForwardBookingCommissionPresenter";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  parseInputFloatToFloat,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { EditForwardBookingCommissionApiRequest } from "@/data/payload/api/ForwardBookingCommissionApiRequest";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import router from "@/app/ui/router";

export interface ForwardBookingcommissionState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  forwardBookingCommissionDetail: DetailForwardBookingCommission;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "forward-booking-commission"
})
class ForwardBookingcommissionStore extends VuexModule
  implements ForwardBookingcommissionState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public forwardBookingCommissionDetail = new DetailForwardBookingCommission();
  public availableShipmentPrefix = [
    "AG",
    "AI",
    "DO",
    "TKP-01",
    "AD",
    "ACA",
    "T1",
    "ARA",
    "AO",
    "ACB",
    "TKLP",
    "ARB",
    "AP",
    "ACR",
    "B1",
    "AS",
    "CCR",
    "B2"
  ];
  public disabledShipmentPrefix = ["T1", "TKLP", "B1", "B2", "TKP-01"];

  @Action
  public async getDetailForwardBookingCommission(id: any) {
    this.setLoading(true);
    const presenter = container.resolve(ForwardBookingCommissionPresenter);
    presenter
      .getDetailForwardBookingCommission(id)
      .then((res: DetailForwardBookingCommission) => {
        this.setDetailForwardBookingcommission(res);
        this.setError(false);
        this.setErrorcause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorcause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public setError(err: boolean) {
    this.isError = err;
  }

  @Mutation
  public setErrorcause(errCause: string) {
    this.errorCause = errCause;
  }

  @Mutation
  public setDetailForwardBookingcommission(
    data: DetailForwardBookingCommission
  ) {
    this.forwardBookingCommissionDetail = data;
  }
  @Mutation
  public setCommissionAppliedTo(data: string) {
    this.forwardBookingCommissionDetail.configurablePriceForwardBookingCommission.commissionAppliedTo = data;
  }
  @Mutation
  public setCommissionShipmentPrefix(data: string) {
    this.forwardBookingCommissionDetail.configurablePriceForwardBookingCommission.commissionPrefix = data;
  }

  // edit
  @Action
  public editForwardBookingCommission(
    detailData: DetailForwardBookingCommission
  ) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ForwardBookingCommissionPresenter);
    return presenter
      .editForwardBookingCommission(
        new EditForwardBookingCommissionApiRequest({
          configurablePriceId: detailData.id,
          configurablePriceDescription: detailData.configurablePriceDescription,
          configurablePriceStatus: detailData.configurablePriceStatus.toLowerCase(),
          configurableForwardBookingCommission: {
            cfbcId:
              detailData.configurablePriceForwardBookingCommission.commissionId,
            cfbcShipmentPrefix:
              detailData.configurablePriceForwardBookingCommission
                .commissionPrefix,
            cfbcAppliedTo:
              detailData.configurablePriceForwardBookingCommission
                .commissionAppliedTo,
            cfbcCommissionAmount: parseInputFloatToFloat(
              detailData.configurablePriceForwardBookingCommission.commission
            ),
            cfbcQuantifier:
              detailData.configurablePriceForwardBookingCommission
                .commissionParameter
          }
        })
      )
      .then(() => {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Perubahan Disimpan",
            message: `Detail Versi “${this.forwardBookingCommissionDetail.configurablePriceName}” berhasil diubah`,
            textSuccess: "Oke",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              router.push(
                `/tarif/configurable-price/forward-booking-commission/${this.forwardBookingCommissionDetail.id}`
              );
            }
          })
        );
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Perubahan Forward Booking Commission Gagal!",
            () => this.editForwardBookingCommission(detailData)
          )
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }
}

export const ForwardBookingCommissionController = getModule(
  ForwardBookingcommissionStore
);
