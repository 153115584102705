/* eslint-disable @typescript-eslint/camelcase */
import { EditInsuranceRequestInteface } from "../contracts/InsuranceRequest";

export class EditInsuranceApiRequest implements EditInsuranceRequestInteface {
  private configurable_price_id: number;
  private configurable_price_type: string;
  private configurable_price_description: string;
  private configurable_price_status: string;
  private vendor_name: string;
  private is_api_url: string;
  private api_url: string;
  private api_token: string;
  private insurances: Array<any>;

  constructor(
    configurable_price_id: number,
    configurable_price_type: string,
    configurable_price_description: string,
    configurable_price_status: string,
    vendor_name: string,
    is_api_url: string,
    api_url: string,
    api_token: string,
    insurances: Array<any>
  ) {
    this.configurable_price_id = configurable_price_id;
    this.configurable_price_type = configurable_price_type;
    this.configurable_price_description = configurable_price_description;
    this.configurable_price_status = configurable_price_status;
    this.vendor_name = vendor_name;
    this.is_api_url = is_api_url;
    this.api_url = api_url;
    this.api_token = api_token;
    this.insurances = insurances;
  }

  public toJSON(): string {
    return JSON.stringify({
      configurable_price_id: this.configurable_price_id,
      configurable_price_type: this.configurable_price_type,
      configurable_price_description: this.configurable_price_description,
      configurable_price_status: this.configurable_price_status,
      configurable_insurance: {
        vendor_name: this.vendor_name,
        is_api_url: this.is_api_url,
        api_url: this.api_url,
        api_token: this.api_token,
        insurance_types: {
          insurances: this.insurances
        }
      }
    });
  }
}
