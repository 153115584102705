
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import { ConfigPriceWoodpackingController } from "@/app/ui/controllers/ConfigPriceWoodpackingController";
import { formatDate, formatPriceIDR, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import router from "@/app/ui/router";
import debounce from "lodash/debounce";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { CityData } from "@/domain/entities/Location";
import { WoodpackingAvailableCities } from "@/domain/entities/ConfigWoodpacking";

@Options({
  components: {
    Input
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ConfigPriceWoodpackingController.setOpenSuccess(false);
      next();
    }
  }
})
export default class EditWoodpacking extends Vue {
  isDisabled = true;
  disabledButton = true;
  openStatus = false;
  modalSuccess = false;
  messageSuccess = "";
  isEditTerminated = false;
  modalSave = false;
  answer = false;
  newPath = "";
  isLoadingCity = false;
  listCity: any = [];
  searchCity: Array<any> = [];

   created() {
    this.getDataDetail();
  }

  async onGetCityList(search: string) {
    try {
      this.isLoadingCity = true;
      this.listCity = await LocationController.getCityList(
        {
          status: "",
          search,
          page: 1,
          limit: 10,
        }
      );
      this.listCity = [
        {
          city_code: "ALL",
          city_name: "Semua",
        },
        ...this.listCity.cityData.map((key: CityData) => {
          return {
            city_code: key.code,
            city_name: key.name,
          };
        }),
      ];
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Mendapatkan List City!", () =>
          this.onGetCityList(search)
        )
      );
    } finally {
      this.isLoadingCity = false;
    }
  }

  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.onGetCityList(search);
  }, 250);

  get isLoading() {
    return ConfigPriceWoodpackingController.isLoading;
  }

  // data detail
  async getDataDetail() {
    const res = await ConfigPriceWoodpackingController.getDetailWoopacking({
      id: Number(this.$route.params.id)
    });
    this.typeValue = res.type;
    this.typeName = this.onMappingType(res.type);
    this.searchCity = res.woodpackingAvailableCities.map((key: WoodpackingAvailableCities)=> {
      return {city_code: key.cityCode, city_name: key.cityName}
    })
  }

  createdAt = "";
  createdBy = "";
  updateAt = "";
  updateBy = "";

  additionalCost = "";
   
  onMappingType(type: string) {
    if (type === "dimension") return "Per Dimensi";
    if (type === "volume-weight") return "Per Berat Volume";
    return type;
  }

  get dataDetail() {
    const detail = ConfigPriceWoodpackingController.configPriceWoodpackingData;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updateAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updateBy = detail.updatedBy;
    this.additionalCost = formatPriceIDR(detail.additionalCost);
    return detail;
  }

  goBack() {
    this.$router.back();
  }

  // Tipe Tambahan Biaya
  typeSelect = false;
  onOpenTypeSelect() {
    this.typeSelect = true;
  }
  onCloseTypeSelect() {
    this.typeSelect = false;
  }
  typeName = "";
  typeValue = "";
  onSelectType(name: string, value: string) {
    this.onCloseTypeSelect();
    this.typeName = name;
    this.typeValue = value;
  }

  typeData = [
    { name: "Percentage", value: 'percentage' },
    { name: "Per Berat Volume", value: "volume-weight" },
    { name: "Per Dimensi", value: "dimension" }
  ];

  typeDataVolume = [
    { name: "Per Berat Volume", value: 'volume-weight' },
    { name: "Percentage", value: "percentage" },
    { name: "Per Dimensi", value: "dimension" }
  ];

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    if (this.isRoute) {
      this.openRoute = true;
    } else {
      ConfigPriceWoodpackingController.setRoute(!this.isRoute);
    }
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  get isRoute() {
    return ConfigPriceWoodpackingController.isRoute;
  }
  changeRoute() {
    ConfigPriceWoodpackingController.setRoute(!this.isRoute);
    this.openRoute = false;
    this.messageSuccess = `Detail Configurable Price "${this.dataDetail.name}" berhasil diubah`;
  }

  replaceComma(str: string) {
    return str.replace(/,/g, "");
  }

  onCloseModalSuccess() {
    this.modalSuccess = false;
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Save data
  openSave() {
    return ConfigPriceWoodpackingController.openModal;
  }
  onOpenSave() {
    ConfigPriceWoodpackingController.setOpenModal(true);
  }
  onCloseSave() {
    ConfigPriceWoodpackingController.setOpenModal(false);
  }

  get openSuccess() {
    return ConfigPriceWoodpackingController.openSuccess;
  }

  onCloseSuccess() {
    router.push(
      `/tarif/configurable-price/woodpacking/${this.$route.params.id}`
    );
  }

  _onEdit() {
    ConfigPriceWoodpackingController.editConfig({
      id: Number(this.$route.params.id),
      typeName: this.dataDetail.name.toLowerCase(),
      description: this.dataDetail.description,
      status: this.isRoute ? "active" : "inactive",
      additionalCost: Number(this.dataDetail.additionalCost),
      type: this.typeValue,
      woodpackingAvailableCities: this.searchCity
    });
  }

  get isFormValid() {
    return (this.searchCity.length && this.typeValue.length)
  }
}
