import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import {
  AdjustmentPenaltyData,
  ConfigurableSttAdjustment
} from "@/domain/entities/AdjustmentPenalty";
import { AdjustmentPenaltyPresenter } from "@/app/ui/presenters/AdjustmentPenaltyPresenter";
import { AdjustmentPenaltyApiRequest } from "@/data/payload/api/AdjustmentPenaltyApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface AdjustmentPenaltyState {
  isLoading: boolean;
  isError: boolean;
  isErrorCause: string;
  adjustmentPenaltyDetail: AdjustmentPenaltyData;
  loadingUpdate: boolean;
  openModal: boolean;
  openModalSuccess: boolean;
  isErrorUpdate: boolean;
  errorCauseUpdate: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "adjustment-controller"
})
class AdjustmentPenaltyStateStore extends VuexModule
  implements AdjustmentPenaltyState {
  public isLoading = false;
  public isError = false;
  public isErrorCause = "";
  public adjustmentPenaltyDetail = new AdjustmentPenaltyData(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    new ConfigurableSttAdjustment(0, 0, "", "", [])
  );
  public loadingUpdate = false;
  public openModal = false;
  public openModalSuccess = false;
  public isErrorUpdate = false;
  public errorCauseUpdate = "";

  @Action
  public getDetailAdjustmentPenalty(params: { id: number }) {
    this.setLoading(true);
    const presenter = container.resolve(AdjustmentPenaltyPresenter);
    presenter
      .getDetailAdjustmentPenalty(params.id)
      .then((res: AdjustmentPenaltyData) => {
        this.setAdjustmentDetailData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public updateAdjustmentPenalty(params: {
    configurablePriceId: number;
    configurablePriceType: string;
    configurablePriceDescription: string;
    configurablePriceStatus: string;
    configurableSttAdjustment: any;
  }) {
    this.setLoadingUpdate(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(AdjustmentPenaltyPresenter);
    presenter
      .updateAdjustmentPenalty(
        new AdjustmentPenaltyApiRequest(
          params.configurablePriceId,
          params.configurablePriceType,
          params.configurablePriceDescription,
          params.configurablePriceStatus,
          params.configurableSttAdjustment
        )
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenModalSuccess(true);
        this.setErrorUpdate(false);
        this.setErrorCauseUpdate("");
      })
      .catch((err: any) => {
        this.setOpenModal(false);
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Perubahan STT Adjustment Penalty Gagal!",
            () => this.updateAdjustmentPenalty(params)
          )
        );
      })
      .finally(() => {
        this.setLoadingUpdate(false);
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setLoadingUpdate(status: boolean) {
    this.loadingUpdate = status;
  }

  @Mutation
  public setOpenModal(status: boolean) {
    this.openModal = status;
  }

  @Mutation
  public setOpenModalSuccess(status: boolean) {
    this.openModalSuccess = status;
  }

  @Mutation
  public setErrorUpdate(status: boolean) {
    this.isErrorUpdate = status;
  }

  @Mutation
  public setErrorCauseUpdate(err: string) {
    this.errorCauseUpdate = err;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setAdjustmentDetailData(data: AdjustmentPenaltyData) {
    this.adjustmentPenaltyDetail = data;
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setErrorCause(err: string) {
    this.isErrorCause = err;
  }
}

export const AdjustmentPenaltyController = getModule(
  AdjustmentPenaltyStateStore
);
