import { ConfigPriceRtsHQMapper } from "@/data/persistences/mappers/ConfigPriceRtsHQMapper";
import { ConfigPriceRtsHQEndpoint } from "../../endpoints/horde/ConfigPriceRtsHQEndpoint";
import ApiService from "../../services/ApiService";
import { ConfigPriceRtsHQRepositoryInterface } from "@/data/persistences/repositories/contracts/ConfigPriceRtsHQRepositoryInterface";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { SubmitConfigPriceRtsHQApiRequest } from "@/data/payload/api/ConfigPriceRtsHQApiRequest";
import { DetailPriceRtsHQ } from "@/domain/entities/ConfigPriceRtsHQ";

export class ConfigPriceRtsHQApiRepository implements ConfigPriceRtsHQRepositoryInterface {
  private service: ApiService;
  private mapper: ConfigPriceRtsHQMapper;
  private endpoints: ConfigPriceRtsHQEndpoint;

  constructor(
    service: ApiService,
    mapper: ConfigPriceRtsHQMapper,
    endpoints: ConfigPriceRtsHQEndpoint
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailConfigPriceRtsHQ(id: number): Promise<DetailPriceRtsHQ> {
    const resp = await this.service.invoke(
      "GET",
      this.endpoints.getDetailConfigPriceRtsHQ(id),
      {}
    );
    return this.mapper.convertDetailConfigPriceRtsHQFromApi(resp);
  }

  public async editConfigPriceRtsHQ(
    payload: SubmitConfigPriceRtsHQApiRequest
  ): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "PUT",
      this.endpoints.editConfigPriceRtsHQ(),
      {},
      payload
    );
    return this.mapper.convertConfigPriceRtsHQDataFromApi(resp);
  }
}
