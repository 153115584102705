
import { Options, Vue } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";
import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";
import { InsuranceController } from "@/app/ui/controllers/InsuranceController";
import Accordion from "@/app/ui/components/accordion/index.vue";
import copy from "copy-text-to-clipboard";

@Options({
  components: {
    Accordion
  }
})
export default class Detail extends Vue {
  isDisabled = true;

  get isEditAble() {
    return checkRolePermission(PRICING_CONFIGURABLE_PRICE.EDIT);
  }

  mounted() {
    this.getDetailInsurance(this.$route.params.id);
  }

  getDetailInsurance(id: any) {
    return InsuranceController.getDetailInsurance({ id });
  }

  fetchDetailInsurance() {
    this.getDetailInsurance(this.$route.params.id);
  }

  get isLoading() {
    return InsuranceController.isLoading;
  }

  get isError() {
    return InsuranceController.isError;
  }

  get errorCause() {
    return InsuranceController.errorCause;
  }

  get statusInsurance() {
    return InsuranceController.isStatusInsurance;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  get insurance(): any {
    return InsuranceController.insurance;
  }

  goBack() {
    this.$router.push("/tarif/configurable-price");
  }

  goEdit(id: any) {
    this.$router.push(`/tarif/configurable-price/insurance/${id}/edit`);
  }

  getCopyText(value: string) {
    copy(value);
    if (copy(value)) {
      alert("copied");
    }
  }
}
