
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import isEqual from "lodash/isEqual";

@Options({
  name: "MultipleSelectBox",
  components: {
    Skeleton
  },
  props: {
    // data list options of select
    dataSelect: {
      default: [],
      type: Array
    },
    // model value can be array of object with name field or array of string
    modelValue: {
      default: [],
      type: Array
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: "",
      type: String
    }
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class extends Vue {
  props: any = this.$props;
  // logic open close menu
  isOpenMenu = false;
  onOpenSelect(value: boolean) {
    this.isOpenMenu = value;
  }
  onChooseSelect(item: any) {
    if (this.isSelected(item)) {
      const newModelValue = this.props.modelValue.filter((data: any) => {
        return !isEqual(data, item);
      });
      this.$emit("update:modelValue", newModelValue);
    } else {
      this.props.modelValue.push(item);
      this.$emit("update:modelValue", this.props.modelValue);
    }
  }

  // logic for selected item
  isSelected(item: any) {
    return this.props.modelValue.find((data: any) => {
      return isEqual(data, item);
    });
  }

  isAllActive = false;
  valueSearch = "";
  onSearch(value: string) {
    this.valueSearch = value;
  }

  // Show value in tagging
  tagging = 0;
  showValue(index: number, length: number) {
    if (length > 1) this.tagging = length - 1;
    else this.tagging = 0;
    return index === 0;
  }
}
