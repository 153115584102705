
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import SelectBox from "@/app/ui/components/multiple-select-box/index.vue";
import { formatDate, formatInputMoney } from "@/app/infrastructures/misc/Utils";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";
import debounce from "lodash/debounce";
import { ProductController } from "@/app/ui/controllers/ProductController";
import OverlayPanel from "primevue/overlaypanel";

@Options({
  components: {
    DetailLayout,
    SelectBox,
    OverlayPanel
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      HeavyWeightSurchargeController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  get createdAt() {
    return formatDate(this.dataDetail.configurablePriceCreatedAt);
  }
  get updatedAt() {
    return formatDate(this.dataDetail.configurablePriceUpdatedAt);
  }

  mounted() {
    this.fetchDetail();
    this.filterProductOptions("");
    this.fetchCommodityList("");
  }

  //misc variable
  isDisabled = true;
  objectAdditionalCosts = [
    {
      name: "Publish Rate",
      type: "publish_rate"
    },
    {
      name: "Shipping Surcharge",
      type: "shipping_surcharge"
    },
    {
      name: "Origin District Rate",
      type: "origin_district_rate"
    },
    {
      name: "Destination District Rate",
      type: "destination_district_rate"
    }
  ];

  //main logic
  // logic detail
  fetchDetail() {
    this.getDetailHeavyWeightSurcharge(this.$route.params.id);
  }
  fetchCommodityList = debounce((searchVal: string) => {
    HeavyWeightSurchargeController.getCommodityList({
      search: searchVal,
      status: "active",
      page: 1,
      limit: 10
    });
  }, 250);
  //Loading
  get isLoading() {
    return HeavyWeightSurchargeController.isLoadingDetail;
  }

  getDetailHeavyWeightSurcharge(id: any) {
    return HeavyWeightSurchargeController.getDetailHeavyWeightSurcharge({ id });
  }
  get dataDetail() {
    return HeavyWeightSurchargeController.heavyWeightSurchargeDetail;
  }
  get configurableHeavyWeight(): any {
    return this.dataDetail.configurableHeavyWeight;
  }
  get additionalCosts() {
    if (this.configurableHeavyWeight) {
      return this.configurableHeavyWeight.heavy_weight_surcharge_additional_cost
        ?.additional_costs;
    }
    return [];
  }
  get commodityExclude() {
    if (this.configurableHeavyWeight) {
      return this.configurableHeavyWeight
        .heavy_weight_surcharge_commodity_exclude?.commodities;
    }
    return [];
  }
  get productExclude() {
    if (this.configurableHeavyWeight) {
      return this.configurableHeavyWeight.heavy_weight_surcharge_product_exclude
        ?.product_types;
    }
    return [];
  }
  get productInclude() {
    if (this.configurableHeavyWeight) {
      return this.configurableHeavyWeight.heavy_weight_surcharge_product_include
        ?.product_types;
    }
    return [];
  }

  _onEdit() {
    //to do on edit
    this.onCloseSave();
    HeavyWeightSurchargeController._onEdit({
      configPriceId: this.dataDetail.id,
      configPriceStatus: this.dataDetail.configurablePriceStatus,
      configPriceDescription: this.dataDetail.configurablePriceDescription,
      configPriceAdditionalCosts: this.convertAdditionalCosts(
        this.additionalCosts
      ),
      configPriceCommodityExclude: this.convertCommodity(this.commodityExclude),
      configPriceProductExclude: this.productExclude,
      configPriceProductInclude: this.productInclude,
      hwSurchargeType: this.dataDetail.heavyweightSurchargeType,
      hwSurchargeDescription: this.dataDetail.configurableHeavyWeight
        .heavy_weight_surcharge_description,
      hwSurchargeId: this.dataDetail.configurableHeavyWeight
        .heavy_weight_surcharge_id,
      hwSurchargeStatus: this.dataDetail.configurableHeavyWeight
        .heavy_weight_surcharge_status
    });
  }

  convertCommodity(data: any) {
    const temp: Array<any> = [];
    data?.map((e: any) => {
      temp.push({
        commodity_name: e.commodity_name,
        commodity_code: e.commodity_code
      });
    });
    return temp;
  }

  convertAdditionalCosts(data: any) {
    const temp: Array<any> = [];
    data?.map((e: any) => {
      temp.push({
        weight_min: parseFloat(e.weight_min),
        weight_max: parseFloat(e.weight_max),
        additional_cost_percentage: this.isHwTypePercentage
          ? parseFloat(e.additional_cost_percentage)
          : 0,
        additional_cost_flat: this.isHwTypePercentage
          ? 0
          : typeof e.additional_cost_flat === "string"
          ? Number(e.additional_cost_flat.replace(/[.]/g, ""))
          : e.additional_cost_flat,
        object_additional_costs: e.object_additional_costs
      });
    });
    return temp;
  }

  // Commodity Exclude
  get commodityListData() {
    return HeavyWeightSurchargeController.commodityOptionsData;
  }
  get isLoadingCommodity() {
    return HeavyWeightSurchargeController.isLoadingCommodity;
  }

  // Product Exclude
  filterProductOptions = debounce((search: string) => {
    ProductController.getProductList({
      search: search,
      page: 1,
      limit: 10,
      productCode: "",
      status: ""
    });
  }, 250);
  get productOptions() {
    return ProductController.productData?.data.map(e => {
      return e.name;
    });
  }
  get isLoadingProductOptions() {
    return ProductController.isLoading;
  }

  //validation form
  get isValidated(): boolean {
    this.onCheckValidated();
    return !!this.additionalCosts.find((item: any) => item.isError);
  }
  onCheckValidated(): void {
    if (this.additionalCosts.length > 1) {
      this.additionalCosts.forEach((item: any, index: number) => {
        if (index > 0) {
          const before = this.additionalCosts[index - 1];

          this.additionalCosts[index]["isError"] =
            Number(item.weight_min) <= Number(before.weight_max) ||
            Number(item.weight_min) >= Number(item.weight_max) ||
            parseFloat(item.weight_min) <= 0 ||
            parseFloat(item.weight_max) <= 0;
        }
      });
    }
  }

  onlyNumber(evt: any, index: number, field: string) {
    if (this.additionalCosts) {
      if (/^0/.test(this.additionalCosts[index][field])) {
        this.additionalCosts[index][field] = this.additionalCosts[index][
          field
        ].replace(/^0/, "");
      } else {
        this.additionalCosts[index][field] = String(
          this.additionalCosts[index][field]
        ).replace(/[\D]+/g, "");
      }
    }
  }

  onlyNumberAndFloat(evt: any, index: number, field: string) {
    if (this.additionalCosts) {
      if (/^0/.test(this.additionalCosts[index][field])) {
        this.additionalCosts[index][field] = this.additionalCosts[index][
          field
        ].replace(/^0/, "");
      } else {
        this.additionalCosts[index][field] = String(
          this.additionalCosts[index][field]
        ).replace(/[^0-9.]+/g, "");
      }
    }
  }

  // create and remove add on logic
  createAddOn() {
    if (this.additionalCosts) {
      if (this.additionalCosts.length > 9) {
        return;
      }
      this.additionalCosts.push({
        id: "",
        weight_min: "",
        weight_max: "",
        additional_cost_percentage: "",
        object_additional_costs: []
      });
    }
  }
  tempIndexToRemove = -1;
  removeAddOn() {
    if (this.tempIndexToRemove > -1) {
      this.onCLoseRemoveConfirmation();
      this.additionalCosts.splice(this.tempIndexToRemove, 1);
      this.tempIndexToRemove = -1;
    }
  }
  //confirmation when remove
  openRemoveConfirmation = false;
  onOpenRemoveConfirmation(index: number) {
    this.tempIndexToRemove = index;
    this.openRemoveConfirmation = true;
  }
  onCLoseRemoveConfirmation() {
    this.openRemoveConfirmation = false;
  }

  // Toggle Status
  openStatus = false;
  onOpenStatus() {
    if (this.isStatus) {
      this.openStatus = true;
    } else {
      this.changeStatus();
    }
  }
  onCloseStatus() {
    this.openStatus = false;
  }
  get isStatus() {
    return (
      this.dataDetail.configurableHeavyWeight.heavy_weight_surcharge_status.toLowerCase() ===
      "active"
    );
  }
  changeStatus() {
    if (this.isStatus) {
      this.onCloseStatus();
      this.dataDetail.configurableHeavyWeight.heavy_weight_surcharge_status =
        "Inactive";
    } else {
      this.dataDetail.configurableHeavyWeight.heavy_weight_surcharge_status =
        "Active";
    }
  }

  // navigation route
  goBack() {
    this.$router.push(
      `/tarif/configurable-price/heavyweight/${this.$route.params.id}`
    );
  }

  // Modal Success
  get openSuccess() {
    return HeavyWeightSurchargeController.openModalSuccess;
  }
  onCloseSuccess() {
    this.goBack();
  }

  // Save data
  openModal = false;
  onOpenSave() {
    this.openModal = true;
  }
  onCloseSave() {
    this.openModal = false;
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  //tooltip hapus daftar
  toggleTooltip(event: any) {
    const refs: any = this.$refs;
    refs?.deleteOp?.toggle(event);
  }
  typeHw = [
    { name: "Flat", value: "flat" },
    { name: "Percentage", value: "percentage" }
  ];
  typeHwSelect = false;
  onOpenTypeHwSelect() {
    this.typeHwSelect = true;
  }

  onCloseTypeHwSelect() {
    this.typeHwSelect = false;
  }

  onSelectTypeHw(_: string, value: string) {
    this.onCloseTypeHwSelect();
    this.dataDetail.heavyweightSurchargeType = value;
  }
  formatPrice(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }
  get isHwTypePercentage() {
    return this.dataDetail.heavyweightSurchargeType === "percentage";
  }
}
