
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import TableV1 from "@/app/ui/components/table/index.vue";
import {
  checkRolePermission,
  formatDate,
  formatPriceIDR
} from "@/app/infrastructures/misc/Utils";
import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";

@Options({
  components: {
    DetailLayout,
    TableV1
  }
})
export default class Detail extends Vue {
  get createdAt() {
    return formatDate(this.dataDetail.configurablePriceCreatedAt);
  }
  get updatedAt() {
    return formatDate(this.dataDetail.configurablePriceUpdatedAt);
  }
  get isEditAble() {
    return checkRolePermission(PRICING_CONFIGURABLE_PRICE.EDIT);
  }

  mounted() {
    this.fetchDetail();
  }

  // misc variable
  modelDialog = false;
  modelDialogProduct = false;
  get columns() {
    const columns = [
      {
        name: "Berat Min.",
        key: "minWeight",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<span class='flex text-black-lp-300'>${item.weight_min} Kg</span>`;
        }
      },
      {
        name: "Berat Maks.",
        key: "maxWeight",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class='flex text-black-lp-300'>${item.weight_max} Kg</div>`;
        }
      },
      {
        name: "Biaya Tambahan",
        key: "additionalCostPercentage",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class='flex text-black-lp-300'>${item.additional_cost_percentage}%</div>`;
        }
      },
      {
        name: "Objek Biaya Tambahan",
        key: "objectAdditionalCosts",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: any) => {
          let stringHtml =
            "<div class='text-black-lp-300 capitalize mr-2 flex flex-wrap gap-2'>";
          for (const index in item.object_additional_costs) {
            stringHtml += `<div class='rounded px-2 py-0 bg-gray-lp-200'>${item.object_additional_costs[index].name}</div>`;
          }
          stringHtml += "</div>";
          return stringHtml;
        }
      }
    ];
    if (this.dataDetail.heavyweightSurchargeType === "flat") {
      columns.splice(2, 2, {
        name: "Biaya Tambahan",
        key: "additionalCostPercentage",
        styleHead: "w-1/20 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class='flex text-black-lp-300'>${formatPriceIDR(
            item.additional_cost_flat
          )}</div>`;
        }
      });
    }
    return columns;
  }

  // logic detail
  fetchDetail() {
    this.getDetailHeavyWeightSurcharge(this.$route.params.id);
  }
  //Loading
  get isLoading() {
    return HeavyWeightSurchargeController.isLoadingDetail;
  }
  //Error
  get isError() {
    return HeavyWeightSurchargeController.isError;
  }
  //Error Cause
  get errorCause() {
    return HeavyWeightSurchargeController.isErrorCause;
  }
  getDetailHeavyWeightSurcharge(id: any) {
    return HeavyWeightSurchargeController.getDetailHeavyWeightSurcharge({ id });
  }
  get dataDetail() {
    return HeavyWeightSurchargeController.heavyWeightSurchargeDetail;
  }
  get configurableHeavyWeight(): any {
    return this.dataDetail.configurableHeavyWeight;
  }
  get commodityExclude() {
    if (this.configurableHeavyWeight) {
      return this.configurableHeavyWeight
        .heavy_weight_surcharge_commodity_exclude?.commodities;
    }
    return [];
  }
  get productExclude() {
    if (this.configurableHeavyWeight) {
      return (
        this.configurableHeavyWeight.heavy_weight_surcharge_product_exclude
          ?.product_types || []
      );
    }
    return [];
  }
  get productInclude() {
    if (this.configurableHeavyWeight) {
      return (
        this.configurableHeavyWeight.heavy_weight_surcharge_product_include
          ?.product_types || []
      );
    }
    return [];
  }
  get additionalCosts() {
    if (this.configurableHeavyWeight) {
      return this.configurableHeavyWeight.heavy_weight_surcharge_additional_cost
        ?.additional_costs;
    }
    return [];
  }

  // route navigatiion
  goBack() {
    this.$router.push("/tarif/configurable-price");
  }
  goEdit() {
    this.$router.push(
      `/tarif/configurable-price/heavyweight/${this.$route.params.id}/edit`
    );
  }
}
