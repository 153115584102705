/* eslint-disable @typescript-eslint/camelcase */
import { RefundConfigurationRequestInterface } from "@/data/payload/contracts/RefundConfiguration";
class ConfigurableRefund {
  refund_id: number;
  origin_city_exclude: string;
  refund_amount: number;
  refund_penalty: number;

  constructor(
    refundId: number,
    originCityExclude: string,
    refundAmount: number,
    refundPenalty: number
  ) {
    this.refund_id = refundId;
    this.origin_city_exclude = originCityExclude;
    this.refund_amount = refundAmount;
    this.refund_penalty = refundPenalty;
  }
}
export class RefundConfigurationApiRequest
  implements RefundConfigurationRequestInterface {
  configurable_price_id: number;
  configurable_price_type: string;
  configurable_price_description: string;
  configurable_price_status: string;
  configurable_refund: ConfigurableRefund[];

  constructor(
    configurable_price_id: number,
    configurable_price_description: string,
    configurable_price_status: string,
    configurable_refund: Array<any>
  ) {
    this.configurable_price_id = configurable_price_id;
    this.configurable_price_type = "refund";
    this.configurable_price_description = configurable_price_description;
    this.configurable_price_status = configurable_price_status;
    this.configurable_refund = configurable_refund.map((val: any) => {
      const refundAmount = Number(
        val.refundAmount.toString().replace(",", ".")
      );
      return new ConfigurableRefund(
        val.refundId,
        val.refundOriginCityExcludeDetail
          .reduce((res: Array<string>, city: any) => {
            if (city.code !== "-") res.push(city.code);
            return res;
          }, [])
          .join(",")
          .toLowerCase(),
        refundAmount,
        100 - refundAmount
      );
    });
  }

  public toJSON(): string {
    return JSON.stringify({
      configurable_price_id: this.configurable_price_id,
      configurable_price_type: this.configurable_price_type,
      configurable_price_description: this.configurable_price_description,
      configurable_price_status: this.configurable_price_status,
      configurable_refund: this.configurable_refund
    });
  }
}
