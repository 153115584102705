
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import ForwardBookingCommissionDetailSectionGeneralConfig from "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-edit/forward-booking-commission-edit-section-general-config.vue";
import ForwardBookingCommissionDetailSectionCommission from "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-edit/forward-booking-commission-edit-section-commission.vue";
import ForwardBookingCommissionDetailSectionAppliedTo from "@/app/ui/views/pricing/config-price/forward-booking-commission/forward-booking-commission-edit/forward-booking-commission-edit-section-applied-to.vue";
import { ForwardBookingCommissionController } from "@/app/ui/controllers/ForwardBookingCommissionController";

@Options({
  components: {
    ForwardBookingCommissionDetailSectionAppliedTo,
    ForwardBookingCommissionDetailSectionCommission,
    ForwardBookingCommissionDetailSectionGeneralConfig,
    DetailLayout,
    CreateUpdate
  }
})
export default class EditForwardRateBookingCommission extends Vue {
  mounted() {
    return ForwardBookingCommissionController.getDetailForwardBookingCommission(
      this.$route.params.id
    );
  }

  get detailData() {
    return ForwardBookingCommissionController.forwardBookingCommissionDetail;
  }

  // route navigatiion
  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    this.$router.push(
      `/tarif/configurable-price/forward-booking-commission/${this.$route.params.id}`
    );
  }

  // Save data
  openModal = false;
  onOpenSave() {
    this.openModal = true;
  }
  onCloseSave() {
    this.openModal = false;
  }

  _onEdit() {
    this.onCloseSave();
    ForwardBookingCommissionController.editForwardBookingCommission(
      this.detailData
    );
  }
}
