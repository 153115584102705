/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { ConfigPricePresenter } from "../presenters/ConfigPricePresenter";
import { ConfigPriceData } from "@/domain/entities/ConfigPrice";
import { Entities } from "@/domain/entities/ResponsePayload";

export interface ConfigPriceState {
  configPriceData: ConfigPriceData[];
  isLoading: boolean;
  isError: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "ConfigPrice" })
class ConfigPriceStore extends VuexModule implements ConfigPriceState {
  public configPriceData: Array<ConfigPriceData> = [];
  public isLoading = false;
  public isError = false;
  public page = 1;
  public limit = 10;
  public search = "";
  public status = "";
  public errorCause = "";

  @Mutation
  public setConfigPriceData(data: ConfigPriceData[]) {
    this.configPriceData = data;
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public async setFirstPage() {
    this.page = 1;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Action
  public _onGetList(params: { page: number; search: string; status: string }) {
    this.setLoading(true);
    const presenter = container.resolve(ConfigPricePresenter);
    presenter
      .getListConfigPrice(params.page, params.search, params.status)
      .then((res: Entities) => {
        this.setConfigPriceData(res.data);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this._onGetList({
      page: this.page,
      search: this.search,
      status: this.status
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this._onGetList({
        page: this.page,
        search: this.search,
        status: this.status
      });
    }
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this._onGetList({
      page: value,
      search: this.search,
      status: this.status
    });
  }
}

export const ConfigPriceController = getModule(ConfigPriceStore);
