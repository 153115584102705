
import { Options, Vue } from "vue-class-component";
import TableV1 from "@/app/ui/components/table/index.vue";
import debounce from "lodash/debounce";
import EmptyList from "@/app/ui/components/empty-list/index.vue";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";
import { ConfigPriceController } from "@/app/ui/controllers/ConfigPriceController";

@Options({
  components: {
    TableV1,
    EmptyList
  }
})
export default class ConfigPrice extends Vue {
  get getCurrentRoute() {
    return this.$route.meta.title;
  }
  get isDetailAble() {
    return checkRolePermission(PRICING_CONFIGURABLE_PRICE.DETAIL);
  }

  // Header Table
  columns = [
    {
      name: "ID Konfig.",
      key: "id",
      styleHead: "w-1/20 text-center whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Configurable Price",
      styleHead: "w-1/5 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.configurablePriceType === "penalty_rtshq" ? "RTSHQ Penalty" : item.configurablePriceName}</div>`;
      }
    },
    {
      name: "Dibuat Oleh",
      key: "name",
      styleHead: "w-3/20 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.configurablePriceCreatedBy}</div>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      key: "eCargoCommodity",
      styleHead: "w-1/5 text-left",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${this.formatDate(
          item.configurablePriceCreatedAt
        )}</div>`;
      }
    },
    {
      name: "Diubah Oleh",
      key: "hsCode",
      styleHead: "w-3/20 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.configurablePriceUpdatedBy}</div>`;
      }
    },
    {
      name: "Terakhir Diubah",
      key: "hsCode",
      styleHead: "w-1/5 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${this.formatDate(
          item.configurablePriceUpdatedAt
        )}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/20 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.configurablePriceStatus.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.configurablePriceStatus.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${
        item.configurablePriceStatus.toLowerCase() === "active"
          ? "Active"
          : "Inactive"
      }</span></div></div>`;
      }
    }
  ];

  chipsProductInclude(val: any) {
    return `<div class='flex text-black-lp-300 mr-2'><div class='rounded-lg px-2 py-0 bg-gray-lp-200'>${val}</div></div>`;
  }

  columnsCollapsible = [
    {
      name: "Nama Configurable Price",
      styleHead: "w-40 text-left",
      styleBody: "w-4/20",
      render: (item: any) => {
        return `<div class='text-black-lp-300 grid gap-1 grid-cols-2'>${item.heavyweightSurchargeCommodityInclude.product_types
          .map((e: any) => this.chipsProductInclude(e))
          .join(" ")}
        </div>`;
      }
    },
    {
      name: "Dibuat Oleh",
      key: "name",
      styleHead: "w-32 text-left",
      styleBody: "text-left rounded py-0 w-3/20",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.heavyweightSurchargeCreatedBy}</div>`;
      }
    },
    {
      name: "Tanggal Dibuat",
      key: "eCargoCommodity",
      styleHead: "w-32 text-left",
      styleBody: "w-4/20",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${this.formatDate(
          item.heavyweightSurchargeCreatedAt
        )}</div>`;
      }
    },
    {
      name: "Diubah Oleh",
      key: "hsCode",
      styleHead: "w-32 text-left",
      styleBody: "text-left rounded py-0 w-3/20",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.heavyweightSurchargeUpdatedBy}</div>`;
      }
    },
    {
      name: "Terakhir Diubah",
      key: "hsCode",
      styleHead: "w-32 text-left",
      styleBody: "text-left rounded py-0 w-1/5",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${this.formatDate(
          item.heavyweightSurchargeUpdatedAt
        )}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-32 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.heavyweightSurchargeStatus.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.heavyweightSurchargeStatus.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize">${
        item.heavyweightSurchargeStatus.toLowerCase() === "active"
          ? "Active"
          : "Inactive"
      }</span></div></div>`;
      }
    }
  ];

  // Pagination
  get page() {
    return ConfigPriceController.page;
  }
  get limit() {
    return ConfigPriceController.limit;
  }
  nextPage() {
    ConfigPriceController.setNextPage();
  }

  prevPage() {
    ConfigPriceController.setPrevPage();
  }

  toPage = debounce(value => {
    ConfigPriceController.setPageAction(value);
  }, 1000);

  // Get Data
  mounted() {
    this.getListData(1, "", "");
    ConfigPriceController.setSearch("");
    this.setFirstPage();
    ConfigPriceController.setStatus("");
  }

  getListData(page: number, search: string, status: string) {
    ConfigPriceController._onGetList({ page, search, status });
  }
  setFirstPage() {
    ConfigPriceController.setFirstPage();
  }
  get listData() {
    return ConfigPriceController.configPriceData.map((item: any) => ({
      ...item,
      subData: item.configurablePriceDetail
    }));
  }
  get isLoading() {
    return ConfigPriceController.isLoading;
  }

  // Filter
  get status() {
    return ConfigPriceController.status;
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    this.statusName = name;
    ConfigPriceController.setStatus(value);
    this.getListData(1, "", value);
  }

  onClickRow(item: any) {
    if (item.configurablePriceType === "insurance") {
      this.$router.push(`/tarif/configurable-price/insurance/${item.id}`);
    } else if (item.heavyweightSurchargeId) {
      this.$router.push(
        `/tarif/configurable-price/heavyweight/${item.heavyweightSurchargeId}`
      );
    } else if (item.configurablePriceType === "woodpacking") {
      this.$router.push(`/tarif/configurable-price/woodpacking/${item.id}`);
    } else if (item.configurablePriceType === "stt_adjustment") {
      this.$router.push(
        `/tarif/configurable-price/adjustment-penalty/${item.id}`
      );
    } else if (item.configurablePriceType === "refund") {
      this.$router.push(`/tarif/configurable-price/refund-config/${item.id}`);
    } else if (item.configurablePriceType === "forward_booking_commission") {
      this.$router.push(
        `/tarif/configurable-price/forward-booking-commission/${item.id}`
      );
    } else if (item.configurablePriceType === "cod") {
      this.$router.push(`/tarif/configurable-price/cod/${item.id}`);
    } else if (item.configurablePriceType === "penalty_rtshq") {
      this.$router.push(`/tarif/configurable-price/rtshq/${item.id}`);
    }
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }

  // Handle Error
  get isError() {
    return ConfigPriceController.isError;
  }
  get errorCause() {
    return ConfigPriceController.errorCause;
  }
  onReload() {
    ConfigPriceController._onGetList({
      page: 1,
      search: "",
      status: ""
    });
  }
}
