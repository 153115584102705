/* eslint-disable @typescript-eslint/camelcase */
import { UpdateAdjustmentPenaltyRequestInterface } from "@/data/payload/contracts/AdjustmentPenaltyRequest";

export class AdjustmentPenaltyApiRequest
  implements UpdateAdjustmentPenaltyRequestInterface {
  configurable_price_id: number;
  configurable_price_type: string;
  configurable_price_description: string;
  configurable_price_status: string;
  configurable_stt_adjustment: any;

  constructor(
    configurable_price_id: number,
    configurable_price_type: string,
    configurable_price_description: string,
    configurable_price_status: string,
    configurable_stt_adjustment: any
  ) {
    this.configurable_price_id = configurable_price_id;
    this.configurable_price_type = configurable_price_type;
    this.configurable_price_description = configurable_price_description;
    this.configurable_price_status = configurable_price_status;
    this.configurable_stt_adjustment = configurable_stt_adjustment;
  }

  public toJSON(): string {
    return JSON.stringify({
      configurable_price_id: this.configurable_price_id,
      configurable_price_type: this.configurable_price_type,
      configurable_price_description: this.configurable_price_description,
      configurable_price_status: this.configurable_price_status,
      configurable_stt_adjustment: this.configurable_stt_adjustment
    });
  }
}
