import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { DetailPriceRtsHQ, RtsHQPenalty } from "@/domain/entities/ConfigPriceRtsHQ";
import { OptionsClass } from "@/domain/entities/MainApp";

export class ConfigPriceRtsHQMapper {
    public convertDetailConfigPriceRtsHQFromApi(
        res: AxiosResponse<any>
    ): DetailPriceRtsHQ {
        const { data } = res.data;
        return new DetailPriceRtsHQ({
            configurablePriceId: data.configurable_price_id,
            configurablePriceName: data.configurable_price_name,
            configurablePriceType: data.configurable_price_type,
            configurablePriceDescription: data.configurable_price_description,
            configurablePriceStatus: data.configurable_price_status,
            configurablePriceCreatedAt: data.configurable_price_created_at,
            configurablePriceCreatedBy: data.configurable_price_created_by,
            configurablePriceUpdatedAt: data.configurable_price_updated_at,
            configurablePriceUpdatedBy: data.configurable_price_updated_by,
            configurableRtshqPenalty: new RtsHQPenalty({
                crpId: data.configurable_rtshq_penalty.id,
                crpRateType: new OptionsClass({
                    name: data.configurable_rtshq_penalty.rate_type,
                    value: data.configurable_rtshq_penalty.rate_type
                }),
                crpPercentage: data.configurable_rtshq_penalty.percentage
            })
        });
    }

    public convertConfigPriceRtsHQDataFromApi(
        result: AxiosResponse
    ): ResponsePayloadV2 {
        const { data } = result;
        return new ResponsePayloadV2({
        success: data.success,
        message: data.message
        });
    }
}
