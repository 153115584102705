import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-screen flex flex-col font-montserrat relative" }
const _hoisted_2 = { class: "flex flex-col bg-white" }
const _hoisted_3 = { class: "w-full h-full bg-white justify-center items-center flex flex-col mx-auto my-20 text-12px xxl:text-14px overflow-hidden" }
const _hoisted_4 = { class: "absolute w-full bottom-0 mb-12" }
const _hoisted_5 = { class: "border-t border-gray-lp-200 border-b overflow-y-auto h-screen pb-24" }
const _hoisted_6 = { class: "absolute w-full bottom-0 mb-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_TableLazyLoad = _resolveComponent("TableLazyLoad")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_empty_list = _resolveComponent("empty-list")!
  const _component_TableV1 = _resolveComponent("TableV1")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        isSelectStatus: true,
        currentRoutes: _ctx.getCurrentRoute,
        statusData: _ctx.statusData,
        statusSelect: _ctx.statusSelect,
        onOpenStatusSelect: _ctx.onOpenStatusSelect,
        onCloseStatusSelect: _ctx.onCloseStatusSelect,
        onSelectStatus: _ctx.onSelectStatus,
        valueStatus: _ctx.statusName
      }, null, 8, ["currentRoutes", "statusData", "statusSelect", "onOpenStatusSelect", "onCloseStatusSelect", "onSelectStatus", "valueStatus"]),
      _withDirectives(_createVNode(_component_TableLazyLoad, { columns: _ctx.columns }, null, 8, ["columns"]), [
        [_vShow, _ctx.isLoading]
      ])
    ]),
    _withDirectives(_createVNode(_component_ErrorMessage, {
      errorType: _ctx.errorCause,
      onTryAgain: _ctx.onReload
    }, null, 8, ["errorType", "onTryAgain"]), [
      [_vShow, _ctx.isError && !_ctx.isLoading]
    ]),
    _withDirectives(_createVNode("div", _hoisted_3, [
      _createVNode(_component_ErrorMessage, { errorType: "notfound" }),
      _withDirectives(_createVNode("div", _hoisted_4, [
        _createVNode(_component_Pagination, {
          onNext: _ctx.nextPage,
          onPrevious: _ctx.prevPage,
          page: _ctx.page,
          toPage: _ctx.toPage
        }, null, 8, ["onNext", "onPrevious", "page", "toPage"])
      ], 512), [
        [_vShow, _ctx.page !== 1]
      ])
    ], 512), [
      [_vShow, !_ctx.listData.length && !_ctx.isLoading && !_ctx.isError && _ctx.statusName]
    ]),
    _withDirectives(_createVNode(_component_empty_list, { data: "data Configurable Price" }, null, 512), [
      [_vShow, 
        !_ctx.listData.length && !_ctx.isLoading && _ctx.page === 1 && !_ctx.isError && !_ctx.statusName
      ]
    ]),
    _withDirectives(_createVNode("div", _hoisted_5, [
      _createVNode(_component_TableV1, {
        columns: _ctx.columns,
        columnsCollipsible: _ctx.columnsCollapsible,
        data: _ctx.listData,
        class: "my-8",
        onClick: _ctx.onClickRow,
        isDetailAble: _ctx.isDetailAble
      }, null, 8, ["columns", "columnsCollipsible", "data", "onClick", "isDetailAble"])
    ], 512), [
      [_vShow, _ctx.listData.length !== 0 && !_ctx.isLoading && !_ctx.isError]
    ]),
    _withDirectives(_createVNode("div", _hoisted_6, [
      _createVNode(_component_Pagination, {
        onNext: _ctx.nextPage,
        onPrevious: _ctx.prevPage,
        totalData: _ctx.listData.length,
        page: _ctx.page,
        toPage: _ctx.toPage,
        isButtonNext: _ctx.listData.length >= _ctx.limit
      }, null, 8, ["onNext", "onPrevious", "totalData", "page", "toPage", "isButtonNext"])
    ], 512), [
      [_vShow, _ctx.listData.length !== 0 && !_ctx.isLoading && !_ctx.isError]
    ])
  ]))
}