/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { DetailPriceRtsHQ } from "@/domain/entities/ConfigPriceRtsHQ";

import snakeCase from "lodash/snakeCase";
export class SubmitConfigPriceRtsHQApiRequest {
    payload : DetailPriceRtsHQ = new DetailPriceRtsHQ();
    constructor(fields?: Partial<SubmitConfigPriceRtsHQApiRequest>) {
        Object.assign(this, fields);
    }

    public toJSON(): string {
        return JSON.stringify(ConvertObjectCamelToSnakeCase({
            configurablePriceId: this.payload.configurablePriceId,
            configurablePriceName: this.payload.configurablePriceName,
            configurablePriceDescription: this.payload.configurablePriceDescription,
            configurablePriceStatus: this.payload.configurablePriceStatus,
            configurablePriceType: snakeCase(this.payload.configurablePriceType.toLowerCase()),
            configurableRtshqPenalty: ConvertObjectCamelToSnakeCase({
                crpId: this.payload.configurableRtshqPenalty.crpId,
                crpPercentage: Number(this.payload.configurableRtshqPenalty.crpPercentage.replaceAll(",", ".")),
                crpRateType: this.payload.configurableRtshqPenalty.crpRateType.value
            })
        }));
    }
}