/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { UpdateStatusRequestInterface } from "../contracts/CustomProcessRequest";
import {
  ConfigPriceCodDetail,
  ConfigurableCod
} from "@/domain/entities/ConfigPriceCod";

export class ConfigPriceCodApiRequest implements UpdateStatusRequestInterface {
  payload: ConfigPriceCodDetail = new ConfigPriceCodDetail();
  constructor(fields?: Partial<ConfigPriceCodApiRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(
      ConvertObjectCamelToSnakeCase(
        new ConfigPriceCodDetail({
          ...this.payload,
          configurableCod: this.payload.configurableCod.map(
            (c: ConfigurableCod) => ({ ...c, ccodFee: +c.ccodFee })
          ),
          configurablePriceStatus: this.payload.isConfigurablePriceStatus
            ? "active"
            : "inactive"
        })
      )
    );
  }
}
