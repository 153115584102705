/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ConfigPriceCodPresenter } from "../presenters/ConfigPriceCodPresenter";
import { ConfigPriceCodApiRequest } from "@/data/payload/api/ConfigPriceCodApiRequest";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "config-price-cod"
})
class ConfigPriceCodStore extends VuexModule {
  @Action
  public getDetail(id: number) {
    const presenter = container.resolve(ConfigPriceCodPresenter);
    return presenter.getDetail(id);
  }

  @Action
  public edit(payload: ConfigPriceCodApiRequest) {
    const presenter = container.resolve(ConfigPriceCodPresenter);
    return presenter.edit(payload);
  }
}

export const ConfigPriceCodController = getModule(ConfigPriceCodStore);
