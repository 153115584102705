
import { Vue, Options } from "vue-class-component";
import Chips from "@/app/ui/components/chips/index.vue";
import { ForwardBookingCommissionController } from "@/app/ui/controllers/ForwardBookingCommissionController";
import startCase from "lodash/startCase";
import {
  formatInputFloat,
  parseInputFloatToFloat
} from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Chips
  }
})
export default class ForwardBookingCommissionEditSectionCommission extends Vue {
  get detailData() {
    return ForwardBookingCommissionController.forwardBookingCommissionDetail;
  }
  get configForwardCommission() {
    return this.detailData.configurablePriceForwardBookingCommission;
  }

  // format commission
  formatCommissionAmount(value: string) {
    const maxValue = 50;
    if (parseInputFloatToFloat(value) > maxValue) {
      return value.substring(0, value.length - 1);
    }
    return formatInputFloat(value, 1);
  }

  get commisionReference() {
    return startCase(
      this.configForwardCommission.commissionReference.split("_").join(" ")
    );
  }
  parameterData = [
    { name: "Origin Forward Rate", value: "origin_forward_rate" },
    { name: "Destination forward rate", value: "destination_forward_rate" },
    {
      name: "Origin forward rate + Destination forward rate",
      value: "origin_forward_rate,destination_forward_rate"
    }
  ];
  parameterSelect = false;
  onOpenParameterSelect() {
    this.parameterSelect = true;
  }

  onCloseParameterSelect() {
    this.parameterSelect = false;
  }

  onSelectParameter(_: string, value: string) {
    this.onCloseParameterSelect();
    this.configForwardCommission.commissionParameter = value;
  }
}
