import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardBookingCommissionDetailSectionGeneralConfig = _resolveComponent("ForwardBookingCommissionDetailSectionGeneralConfig")!
  const _component_ForwardBookingCommissionDetailSectionCommission = _resolveComponent("ForwardBookingCommissionDetailSectionCommission")!
  const _component_ForwardBookingCommissionDetailSectionAppliedTo = _resolveComponent("ForwardBookingCommissionDetailSectionAppliedTo")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CreateUpdate = _resolveComponent("CreateUpdate")!
  const _component_button_fill = _resolveComponent("button-fill")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    onBack: _ctx.goBack,
    title: _ctx.detailData.configurablePriceName
  }, {
    data: _withCtx(() => [
      _createVNode(_component_ForwardBookingCommissionDetailSectionGeneralConfig),
      _createVNode(_component_ForwardBookingCommissionDetailSectionCommission),
      _createVNode(_component_ForwardBookingCommissionDetailSectionAppliedTo)
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, { label: "Status Configurable Price" }, {
        default: _withCtx(() => [
          _createVNode(_component_Chips, {
            class: "mt-1.5",
            label: _ctx.detailData.configurablePriceStatus,
            textColor: 
            _ctx.detailData.configurablePriceStatus.toLowerCase() === 'active'
              ? 'green-lp-200'
              : 'red-lp-200'
          ,
            color: 
            _ctx.detailData.configurablePriceStatus.toLowerCase() === 'active'
              ? 'green-lp-300'
              : 'red-300'
          ,
            rounded: ""
          }, null, 8, ["label", "textColor", "color"])
        ]),
        _: 1
      }),
      _createVNode(_component_CreateUpdate, {
        createdAt: _ctx.detailData.configurablePriceCreatedAt,
        createdBy: _ctx.detailData.configurablePriceCreatedBy,
        updatedAt: _ctx.detailData.configurablePriceUpdatedAt,
        updatedBy: _ctx.detailData.configurablePriceUpdatedBy
      }, null, 8, ["createdAt", "createdBy", "updatedAt", "updatedBy"]),
      _createVNode("div", _hoisted_1, [
        (_ctx.isAbleToEdit)
          ? (_openBlock(), _createBlock(_component_button_fill, {
              key: 0,
              title: "Edit",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goEdit())),
              class: "px-8 bg-red-lp-300"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onBack", "title"]))
}