import { injectable } from "tsyringe";
import { ConfigPriceRtsHQApiRepository } from "@/app/infrastructures/repositories/api/ConfigPriceRtsHQApiRepository";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { SubmitConfigPriceRtsHQApiRequest } from "@/data/payload/api/ConfigPriceRtsHQApiRequest";
import { DetailPriceRtsHQ } from "@/domain/entities/ConfigPriceRtsHQ";

@injectable()
export class ConfigPriceRtsHQPresenter {
  private repository: ConfigPriceRtsHQApiRepository;

  constructor(repository: ConfigPriceRtsHQApiRepository) {
    this.repository = repository;
  }

  public getDetailConfigPriceRtsHQ(id: number): Promise<DetailPriceRtsHQ> {
    return this.repository.getDetailConfigPriceRtsHQ(id);
  }

  editConfigPriceRtsHQ(
    payload: SubmitConfigPriceRtsHQApiRequest
  ): Promise<ResponsePayloadV2> {
    return this.repository.editConfigPriceRtsHQ(payload);
  }
}
