
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import formatInputDecimal from "@/app/infrastructures/misc/common-library/FormatInputDecimal";
import { ConfigPriceRtsHQController } from "@/app/ui/controllers/ConfigPriceRtsHQController";
import { SubmitConfigPriceRtsHQApiRequest } from "@/data/payload/api/ConfigPriceRtsHQApiRequest";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { DetailPriceRtsHQ, RtsHQPenalty } from "@/domain/entities/ConfigPriceRtsHQ";

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class Edit extends Vue {
  leavePageConfirmation = false;
  answerLeavingPage = false;
  nextPath = "";
  goBack() {
    const path = `/tarif/configurable-price/rtshq/${this.$route.params.id}`;
    this.$router.push(path);
  }

  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }

  mounted() {
    this.fetchDetail();
  }

  isLoading = false;
  errorCause = "";
  async fetchDetail() {
    this.isLoading = true;
    this.errorCause = "";

    try {
        const detail = await ConfigPriceRtsHQController.getDetailConfigPriceRtsHQ(
            Number(this.$route.params.id)
        );
    
        const penalty = String(detail.configurableRtshqPenalty.crpPercentage).replaceAll(
            ".",
            ","
        );

        this.form = {
          configurablePriceId: detail.configurablePriceId,
          configurablePriceName: detail.configurablePriceName,
          configurablePriceType: detail.configurablePriceType,
          configurablePriceDescription: detail.configurablePriceDescription,
          configurablePriceStatus: detail.configurablePriceStatus,
          configurableRtshqPenalty: new RtsHQPenalty({
            crpId: detail.configurableRtshqPenalty.crpId,
            crpPercentage: penalty,
            crpRateType: new OptionsClass({
              name: detail.configurableRtshqPenalty.crpRateType.name,
              value: detail.configurableRtshqPenalty.crpRateType.value
            })
          }),
          configurablePriceCreatedAt: detail.configurablePriceCreatedAt,
          configurablePriceCreatedBy: detail.configurablePriceCreatedBy,
          configurablePriceUpdatedAt: detail.configurablePriceUpdatedAt,
          configurablePriceUpdatedBy: detail.configurablePriceUpdatedBy
        }
        
        this.statusToggle = detail.configurablePriceStatus.toLowerCase() === "active"
        this.validatePercentagePenalty(penalty);
    } catch (error) {
      this.errorCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
    }
  }

  async onSaveForm() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Simpan Perubahan “Konfigurasi RTSHQ”?",
        message:
          "Pastikan kembali, detail konfigurasi biaya yang diperbarui sudah benar dan sesuai",
        textCancel: "Tidak",
        textSuccess: "Ya",
        onSubmit: () => this.submitDataPayload(),
        onClose: () => MainAppController.closeMessageModal(),
        image: "badge-confirmation-general"
      })
    );
  }

  get isFormValid() {
    return (
        !!this.form.configurablePriceId && 
        !!this.form.configurablePriceName &&
        !!this.form.configurablePriceDescription && 
        !!this.form.configurableRtshqPenalty.crpRateType.value && 
        !!this.form.configurableRtshqPenalty.crpPercentage && 
        this.form.configurablePriceDescription.length >= 3 && 
        !this.error.percentagePenalty.error
    );
  }

  async submitDataPayload() {
    MainAppController.closeErrorMessage();
    MainAppController.closeMessageModal();
    MainAppController.showLoading();

    try {
      await ConfigPriceRtsHQController.editConfigPriceRtsHQ(
        new SubmitConfigPriceRtsHQApiRequest({
          payload: this.form
        })
      );
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "“Konfigurasi RTSHQ” Berhasil Diperbarui",
          message: "Perubahan detail konfigurasi biaya telah tersimpan",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, "Perubahan “Konfigurasi RTSHQ” Gagal !", () =>
          this.submitDataPayload()
        )
      );
    }

    MainAppController.closeLoading();
  }

  form = new DetailPriceRtsHQ();

  formatInputFloat(value: string) {
    return formatInputDecimal(value);
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  get tariffs() {
    return [
      {
        name: "Pilih Tipe Tarif",
        value: ""
      },
      {
        name: "Total Tarif Origin",
        value: "Total Tarif Origin"
      },
      {
        name: "Total Tarif RTSHQ",
        value: "Total Tarif RTSHQ"
      }
    ];
  }

  error = {
    percentagePenalty: {
      error: false,
      errorCaption: ""
    }
  };

  validatePercentagePenalty(value: string) {
    const newValue = value === "" ? "" : Number(value.replace(/,/g, "."));
    if (newValue > 100 && newValue !== "") {
      this.error.percentagePenalty.error = true;
      this.error.percentagePenalty.errorCaption =
        "Persentase Denda Penalti harus kurang dari 100%";
    } else {
      this.error.percentagePenalty.error = false;
      this.error.percentagePenalty.errorCaption = "";
    }
  }

  get convertCreatedAt() {
    return formatDate(this.form.configurablePriceCreatedAt);
  }

  get convertUpdatedAt() {
    return formatDate(this.form.configurablePriceUpdatedAt);
  }

  dialogToggle = {
    title: "Konfirmasi Pengaktifan",
    icon: "are-you-sure",
    message: "Anda yakin ingin mengaktifkan Harga “RTSHQ Penalty” ?"
  }

  openDialogStatusToggle = false;
  statusToggle = false;
  openStatusConfirmation() {
    this.changeDialogToggle(this.statusToggle)
    this.statusToggle = !this.statusToggle;
    this.openDialogStatusToggle = true;
  }

  changeDialogToggle(status: boolean) {
    this.dialogToggle.title = status 
      ? "Konfirmasi Penonaktifan" 
      : "Konfirmasi Pengaktifan";
    this.dialogToggle.icon = status 
      ? "warning" 
      : "are-you-sure";
    this.dialogToggle.message = status 
      ? "Anda yakin ingin menonaktifkan Harga “RTSHQ Penalty” ?" 
      : "Anda yakin ingin mengaktifkan Harga “RTSHQ Penalty” ?"
  }

  onCancelStatusToggle() {
    this.openDialogStatusToggle = false;
    this.statusToggle = !this.statusToggle;
  }

  onSubmitStatusToggle() {
    this.openDialogStatusToggle = false;
    this.form.configurablePriceStatus = this.statusToggle ? "active" : "inactive";
  }

}
