
import { Options, Vue } from "vue-class-component";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import RadioButton from "primevue/radiobutton";
import debounce from "lodash/debounce";
import { GoodsTaxController } from "@/app/ui/controllers/GoodsTaxController";

interface OptiosInterface {
  name: string;
  code: string;
}

@Options({
  emits: ["change", "update:modelValue"],
  components: {
    CustomDropdown,
    RadioButton
  },
  props: {
    placeholder: {
      default: "Pilih",
      type: String
    },
    modelValue: {
      default: [],
      required: true
    }
  }
})
export default class CityExclude extends Vue {
  props: any = this.$props;

  cityExcludeOpened = true;

  get selectedValueName() {
    return this.selectedValue
      .filter(item => item.name !== "" && item.name !== "-")
      .map(item => item.name)
      .join(", ");
  }

  get selectedValue() {
    if (this.radioValue === "No City") {
      return [{ name: "No City", code: "no_city", freeTradeZone: "" }];
    } else {
      return [
        {
          name: this.selectedCustomCity.name,
          code: this.selectedCustomCity.code,
          freeTradeZone: ""
        },
        ...this.selectedFtz.filter(
          () => this.selectedCustomCity.code.toLowerCase() !== "non_ftz"
        ),
        ...this.selectedNonFtz.filter(
          () => this.selectedCustomCity.code.toLowerCase() !== "ftz"
        )
      ];
    }
  }

  //selected items
  selectedCustomCity = { name: "-", code: "-" };

  selectedFtz = [];

  selectedNonFtz = [];

  get parseNoCityValue() {
    return this.props.modelValue.find(
      (item: any) => item.code.toLowerCase() === "no_city"
    )
      ? "No City"
      : "Custom City";
  }
  get watchModelValue() {
    this.radioValue =
      this.props.modelValue.length > 0 ? this.parseNoCityValue : "Custom City";

    this.selectedCustomCity =
      this.props.modelValue.length > 0
        ? this.props.modelValue
            .filter((item: any) => item.code.toLowerCase().includes("ftz"))
            .pop() || { name: "-", code: "-" }
        : { name: "-", code: "-" };

    this.selectedFtz =
      this.props.modelValue.length > 0
        ? this.props.modelValue.filter(
            (item: any) =>
              item.freeTradeZone.toLowerCase() === "yes" &&
              !item.code.toLowerCase().includes("ftz")
          ) || []
        : [];

    this.selectedNonFtz =
      this.props.modelValue.length > 0
        ? this.props.modelValue.filter(
            (item: any) =>
              item.freeTradeZone.toLowerCase() === "no" &&
              !item.code.toLowerCase().includes("ftz")
          ) || []
        : [];
    return "";
  }
  //radio
  radioValue = "";

  // custom city options
  customCityOpened = false;
  customCityOptions = [
    { name: "-", code: "-" },
    { name: "Only FTZ City", code: "ftz" },
    { name: "Only Non-FTZ City", code: "non_ftz" }
  ];

  onSelectCustomCity(customCity: OptiosInterface) {
    if (this.selectedCustomCity.code === customCity.code) {
      this.selectedCustomCity = { name: "-", code: "-" };
    } else {
      this.selectedCustomCity = customCity;
    }
    this.customCityOpened = false;
    this.onChange();
  }

  isFtz = false;

  fetchCity = debounce((search: string, ftz: string) => {
    if (search.length > 2 || !search) {
      GoodsTaxController.getCityList({
        search,
        status: "",
        page: 1,
        limit: 10,
        freeTradeZone: ftz
      });
    }
  }, 200);

  onFocusCity(search: string, ftz: string) {
    GoodsTaxController.getCityList({
      search,
      status: "",
      page: 1,
      limit: 10,
      freeTradeZone: ftz
    });
  }

  ftzCityList: any = [];
  nonFtzCityList: any = [];

  get cityList() {
    return GoodsTaxController.cityData.cityData.filter(
      item => item.name !== ""
    );
  }

  get isLoadingCity() {
    return GoodsTaxController.isLoadingAdd;
  }

  onChange() {
    this.$emit("change", this.selectedValue);
    this.$emit("update:modelValue", this.selectedValue);
  }

  onReset(ftz: string) {
    if (ftz === "yes") {
      this.selectedFtz = [];
    } else {
      this.selectedNonFtz = [];
    }
    this.onChange();
  }
}
