import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap ml-6 mt-4" }
const _hoisted_3 = { class: "flex my-1" }
const _hoisted_4 = { class: "capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_DetailLayout, {
      scrollSidebar: "",
      customClass: "px-0",
      onBack: _ctx.goBack,
      title: _ctx.getTitle,
      loading: _ctx.isLoading,
      error: _ctx.isError,
      errorType: _ctx.errorCause,
      onTryAgain: _ctx.getDetailAdjustment
    }, {
      data: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "ID Configurable",
            value: _ctx.configurableId
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Nama Harga",
            value: _ctx.detailData.configurablePriceName
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Adjusted By",
            value: _ctx.detailData.configurableSttAdjustment.penaltyAdjustedBy
          }, null, 8, ["value"])
        ])
      ]),
      "sub-data": _withCtx(() => [
        _createVNode(_component_DataWrapper, {
          label: "Status Harga",
          class: "mt-4"
        }, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_3, [
              _createVNode("div", {
                class: 
                `rounded-full px-2 py-0 text-center ${
                  _ctx.detailData.configurablePriceStatus === 'Active'
                    ? 'bg-green-lp-300 text-green-lp-200'
                    : 'bg-red-300 text-red-lp-200'
                }`
              
              }, [
                _createVNode("span", _hoisted_4, _toDisplayString(_ctx.detailData.configurablePriceStatus), 1)
              ], 2)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_DataWrapper, {
          label: "Tanggal Dibuat",
          value: _ctx.convertCreatedAt,
          caption: _ctx.detailData.configurablePriceCreatedBy
        }, null, 8, ["value", "caption"]),
        _createVNode(_component_DataWrapper, {
          label: "Tanggal Diubah",
          value: _ctx.convertUpdatedAt,
          caption: _ctx.detailData.configurablePriceUpdatedBy
        }, null, 8, ["value", "caption"]),
        _createVNode(_component_LpButton, {
          customClass: "px-12 py-2.5 text-white",
          title: "Ubah",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEdit(_ctx.$route.params.id)))
        })
      ]),
      _: 1
    }, 8, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"])
  ]))
}