
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";
import {
  ConfigPriceCodDetail,
  ConfigurableCod
} from "@/domain/entities/ConfigPriceCod";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigPriceCodController } from "@/app/ui/controllers/ConfigPriceCodController";
import {
  ErrorMessageEntities,
  ModalMessageEntities
} from "@/domain/entities/MainApp";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ConfigPriceCodApiRequest } from "@/data/payload/api/ConfigPriceCodApiRequest";

@Options({
  components: {
    DetailLayout
  },
  beforeRouteLeave(to: any, _: any, next: any) {
    if (this.answerLeavingPage || !this.isEdit || to.name === "login") {
      this.answerLeavingPage = false;
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class Detail extends Vue {
  get id(): number {
    return Number(this.$route.params?.id);
  }

  get isEdit(): boolean {
    return !!(this.$route?.name as string)?.match(/edit/gi);
  }

  detailEntities = new ResponsePayloadV2();
  get detailData(): ConfigPriceCodDetail {
    return this.detailEntities.data;
  }
  async fetchDetail(): Promise<void> {
    try {
      this.detailEntities.loading = true;
      this.detailEntities = await ConfigPriceCodController.getDetail(this.id);
    } catch (error) {
      const errorEntities: ErrorMessageEntities = parsingErrorResponse(error);
      this.detailEntities.message = errorEntities.type;
    } finally {
      this.detailEntities.loading = false;
    }
  }

  async mounted() {
    this.fetchDetail();
  }

  goBack(): void {
    if (this.id) {
      this.$router.push("/tarif/configurable-price");
    } else {
      this.$router.push(`/tarif/configurable-price/cod/${this.id}`);
    }
  }
  goEdit(): void {
    this.$router.push(`/tarif/configurable-price/cod/${this.id}/edit`);
  }

  get isEditable(): boolean {
    return checkRolePermission(PRICING_CONFIGURABLE_PRICE.EDIT);
  }

  // table
  get columns(): any[] {
    return [
      {
        name: "Nama Fee",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: ConfigurableCod) =>
          `<span class="font-normal text-black-lp-300 whitespace-no-wrap">${item.ccodName}</span>`
      },
      {
        name: "Tipe Perhitungan",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: ConfigurableCod) =>
          ChipsStyles({
            status: "gray",
            title: item.ccodTypeCalculation,
            square: true
          })
      },
      {
        name: "Parameter",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: ConfigurableCod) =>
          ChipsStyles({
            status: "gray",
            title: item.ccodCodType,
            square: true
          })
      },
      {
        name: "Acuan Perhitungan",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: ConfigurableCod) =>
          `<span class="font-normal text-black-lp-300">${item.ccodReferences}</span>`
      },
      {
        name: "Biaya Fee",
        key: this.isEdit ? "input-cell" : "",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: ConfigurableCod, index: number) =>
          this.isEdit
            ? {
                modelValue: item.ccodFee,
                placeholder: "Masukan biaya fee",
                type: "number",
                min: "0",
                max: "100",
                suffix: "%",
                error: this.detailData.configurableCod[index].isError,
                errorIcon: false,
                isErrorIconCaption: false,
                onChange: (val: string) =>
                  (this.detailData.configurableCod[index].ccodFee = val),
                "onUpdate:isError": (val: boolean) =>
                  (this.detailData.configurableCod[index].isError = val)
              }
            : `<span class="font-normal text-black-lp-300">${item.ccodFee}%</span>`
      }
    ];
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }

  get isDisabled(): boolean {
    return (
      !this.detailData.configurablePriceDescription ||
      !!this.detailData.configurableCod.find((e: ConfigurableCod) => e.isError)
    );
  }
  onConfirmSave(): void {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Konfirmasi Perubahan",
        message: `Anda yakin ingin menyimpan perubahan detail harga “${this.detailData.configurablePriceName}”?`,
        image: "are-you-sure",
        textCancel: "Kembali",
        textSuccess: "OK",
        onClose: MainAppController.closeMessageModal,
        onSubmit: this.onSave
      })
    );
  }

  async onSave(): Promise<void> {
    try {
      MainAppController.showLoading();
      MainAppController.closeMessageModal();
      MainAppController.closeErrorMessage();
      await ConfigPriceCodController.edit(
        new ConfigPriceCodApiRequest({
          payload: this.detailData
        })
      );
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Perubahan berhasil disimpan",
          message: `Detail harga “${this.detailData.configurablePriceName}” berhasil diubah.`,
          textSuccess: "OK",
          onSubmit: this.onCloseSuccess,
          image: "image-modal-success"
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          `Detail Harga ${this.detailData.configurablePriceName} Gagal Diubah`,
          () => this.onSave()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  nextPath = "";
  leavePageConfirmation = false;
  answerLeavingPage = false;
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }
}
