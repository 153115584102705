
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import {
  dateToDateString,
  dateToTime,
  formatInputMoney,
  formatInputMoneyWithDecimal,
  replaceComma
} from "@/app/infrastructures/misc/Utils";
import { InsuranceController } from "@/app/ui/controllers/InsuranceController";
import { AuthController } from "@/app/ui/controllers/AuthController";
import Accordion from "@/app/ui/components/accordion/index.vue";
import Input from "@/app/ui/components/input/index.vue";

@Options({
  components: {
    Input,
    Accordion
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.modalSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      InsuranceController.setModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  isDisabled = true;
  apiData = [
    { name: "Yes", value: "yes" },
    { name: "Manual", value: "manual" }
  ];
  apiSelect = false;
  statusApi = "";
  showSuccess = false;
  showTest = false;
  openStatusConfigurablePrice = false;
  isEditTerminated = false;
  answer = false;
  newPath = "";
  modalSave = false;
  convertedInsurances: Array<any> = [];
  showError = false;

  onOpenApiSelect() {
    this.apiSelect = true;
  }

  onCloseApiSelect() {
    this.apiSelect = false;
  }

  convertInputMoney(value: string) {
    if (String(value)?.charAt(0) === "0") {
      return String(value)?.replace(String(value)?.charAt(1), "");
    } else {
      value = String(value)?.replace(/[\D]+/g, "");
      return formatInputMoney(value);
    }
  }

  onInputFormatRupiahMaxPrice(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].priceGoodsMax = this.convertInputMoney(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.priceGoodsMax
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .priceGoodsMax;
  }

  onInputFormatRupiahMaxPriceMY(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].priceGoodsMaxMY = formatInputMoneyWithDecimal(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.priceGoodsMaxMY
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .priceGoodsMaxMY;
  }


  onInputFormatRupiahMinPrice(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].priceGoodsMin = this.convertInputMoney(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.priceGoodsMin
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .priceGoodsMin;
  }

  onInputFormatRupiahMinPriceMY(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].priceGoodsMinMY = formatInputMoneyWithDecimal(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.priceGoodsMinMY
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .priceGoodsMinMY;
  }

  onInputFormatRupiahPremiMax(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].pricePremiMax = this.convertInputMoney(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.pricePremiMax
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .pricePremiMax;
  }

  onInputFormatRupiahPremiMaxMY(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].pricePremiMaxMY = formatInputMoneyWithDecimal(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.pricePremiMaxMY
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .pricePremiMaxMY;
  }

  onInputFormatRupiahPremiMin(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].pricePremiMin = this.convertInputMoney(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.pricePremiMin
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .pricePremiMin;
  }

  onInputFormatRupiahPremiMinMY(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].pricePremiMinMY = formatInputMoneyWithDecimal(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.pricePremiMinMY
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .pricePremiMinMY;
  }


  onInputFormatFeeInsurance(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].cost = formatInputMoneyWithDecimal(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.cost
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx].cost;
  }

  onInputFormatCommisionPos(idx: number) {
    this.insurance.configurableInsurance.insuranceTypes[
      idx
    ].commisionPos = formatInputMoneyWithDecimal(
      this.insurance.configurableInsurance.insuranceTypes[idx]?.commisionPos
    );
    return this.insurance.configurableInsurance.insuranceTypes[idx]
      .commisionPos;
  }

  onOpenStatusConfigurablePrice() {
    if (this.statusInsurance()) {
      this.openStatusConfigurablePrice = !this.openStatusConfigurablePrice;
    } else {
      this.openStatusConfigurablePrice = false;
      InsuranceController.setStatusInsurance(true);
    }
  }

  onCloseStatusConfigurablePrice() {
    this.openStatusConfigurablePrice = false;
  }

  changeStatusConfigurablePrice() {
    InsuranceController.setStatusInsurance(!this.statusInsurance());
    this.onCloseStatusConfigurablePrice();
  }

  onSelectApi(_: string, value: string) {
    this.onCloseApiSelect();
    this.insurance.configurableInsurance.insuranceIsApiUrl = value;
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  onCloseModalSuccess() {
    this.goBack();
  }

  openSave() {
    return InsuranceController.modalSave;
  }

  openModalSave() {
    InsuranceController.setModalSave(true);
  }

  closeModalSave() {
    InsuranceController.setModalSave(false);
  }

  onEditConfigPrice() {
    this.setInsurances(this.insurance.configurableInsurance.insuranceTypes);
    const payload = {
      configurablePriceId: this.insurance.configPrice.id,
      configurablePriceType: this.insurance.configPrice.configurablePriceType,
      configurablePriceDescription: this.insurance.configPrice
        .configurablePriceDescription,
      configurablePriceStatus: this.statusInsurance() ? "Active" : "Inactive",
      vendorName: this.insurance.configurableInsurance.insuranceVendorName,
      isApiUrl: this.insurance.configurableInsurance.insuranceIsApiUrl,
      apiUrl: this.insurance.configurableInsurance.insuranceApiUrl,
      apiToken: this.insurance.configurableInsurance.insuranceApiToken,
      insurances: this.convertedInsurances
    };
    InsuranceController.editInsurance(payload);
    this.closeModalSave();
  }

  replaceComma(str: string) {
    return replaceComma(str);
  }

  setInsurances(insurances: Array<any>) {
    this.convertedInsurances = insurances.map(key => ({
      id: key.id,
      type: key.type,
      name: key.name,
      price_goods_min: Number(this.replaceComma(key.priceGoodsMin)),
      price_goods_max: Number(this.replaceComma(key.priceGoodsMax)),
      price_premi_min: Number(this.replaceComma(key.pricePremiMin)),
      price_premi_max: Number(this.replaceComma(key.pricePremiMax)),
      price_goods_min_currency_myr: Number(key.priceGoodsMinMY.replaceAll(".", "").replaceAll(",", ".")),
      price_goods_max_currency_myr: Number(key.priceGoodsMaxMY.replaceAll(".", "").replaceAll(",", ".")),
      price_premi_min_currency_myr: Number(key.pricePremiMinMY.replaceAll(".", "").replaceAll(",", ".")),
      price_premi_max_currency_myr: Number(key.pricePremiMaxMY.replaceAll(".", "").replaceAll(",", ".")),
      cost: Number(String(key.cost).replace(",", ".")),
      commission_pos: Number(String(key.commisionPos).replace(",", "."))
    }));
  }

  mounted() {
    this.getDetailInsurance(this.$route.params.id);
  }

  getDetailInsurance(id: any) {
    return InsuranceController.getDetailInsurance({ id });
  }

  fetchDetailInsurance() {
    this.getDetailInsurance(this.$route.params.id);
  }

  get isLoading() {
    return InsuranceController.isLoading;
  }

  get modalSuccess() {
    return InsuranceController.modalSuccess;
  }

  get messageSuccess() {
    return InsuranceController.messageSuccess;
  }

  get isValidate() {
    const result = true;
    for (
      let i = 0;
      i < this.insurance.configurableInsurance.insuranceTypes.length;
      i++
    ) {
      const key = this.insurance.configurableInsurance.insuranceTypes[i];
      if (this.insurance.configurableInsurance.insuranceIsApiUrl === "manual") {
        if (
          !this.insurance.configurableInsurance.insuranceVendorName ||
          !key.priceGoodsMin ||
          !key.priceGoodsMax ||
          !key.pricePremiMax ||
          !key.pricePremiMin ||
          !key.priceGoodsMinMY ||
          !key.priceGoodsMaxMY ||
          !key.pricePremiMaxMY ||
          !key.pricePremiMinMY ||
          !key.cost ||
          !key.commisionPos
        ) {
          return false;
        }
      } else if (
        !this.insurance.configurableInsurance.insuranceVendorName ||
        !this.insurance.configurableInsurance.insuranceApiUrl ||
        !this.insurance.configurableInsurance.insuranceApiToken ||
        !key.priceGoodsMin ||
        !key.priceGoodsMax ||
        !key.pricePremiMax ||
        !key.pricePremiMin ||
        !key.priceGoodsMinMY ||
        !key.priceGoodsMaxMY ||
        !key.pricePremiMaxMY ||
        !key.pricePremiMinMY ||
        !key.cost ||
        !key.commisionPos
      ) {
        return false;
      }
      if (
        Number(this.replaceComma(key.priceGoodsMin)) >
          Number(this.replaceComma(key.priceGoodsMax)) ||
        Number(this.replaceComma(key.pricePremiMin)) >
          Number(this.replaceComma(key.pricePremiMax)) ||
        Number(key.priceGoodsMinMY.replaceAll(".", "").replaceAll(",", ".")) >
          Number(key.priceGoodsMaxMY.replaceAll(".", "").replaceAll(",", ".")) ||
        Number(key.pricePremiMinMY.replaceAll(".", "").replaceAll(",", ".")) >
          Number(key.pricePremiMaxMY.replaceAll(".", "").replaceAll(",", "."))
      ) {
        return false;
      }
      if (
        this.isErrorMaxPriceGoods(i) ||
        this.isErrorMaxPriceGoodsMY(i) ||
        this.isErrorMinInsurancePayment(i) ||
        this.isErrorMinInsurancePaymentMY(i) ||
        this.isErrorMaxInsurancePayment(i) ||
        this.isErrorMaxInsurancePaymentMY(i) ||
        this.isErrorInsuranceCost(i)
      ) {
        return false;
      }
    }
    return result;
  }

  statusInsurance() {
    return InsuranceController.isStatusInsurance;
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  get insurance(): any {
    return InsuranceController.insurance;
  }

  goBack() {
    this.$router.push(
      `/tarif/configurable-price/insurance/${this.$route.params.id}`
    );
  }

  testApi() {
    this.showTest = true;
    this.showSuccess = false;
    this.showError = false;
    axios
      .get(`${this.insurance.configurableInsurance.insuranceApiUrl}`, {
        headers: {
          Authorization: `Bearer ${AuthController.token}`
        }
      })
      .then((res: any) => {
        if (res.status === 200) {
          this.showSuccess = true;
          this.showError = false;
        }
      })
      .catch(() => {
        this.showError = true;
        this.showSuccess = false;
      })
      .finally(() => {
        this.showTest = false;
      });
  }

  isInputShouldDisabled(insurance: any) {
    return insurance.type === "free";
  }

  isErrorMaxPriceGoods(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(this.replaceComma(insurance.priceGoodsMax)) <= 0;
  }

  isErrorMaxPriceGoodsMY(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(insurance.priceGoodsMaxMY.replaceAll(".", "").replaceAll(",", ".")) <= 0;
  }

  isErrorMinInsurancePayment(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(this.replaceComma(insurance.pricePremiMin)) <= 0;
  }

  isErrorMinInsurancePaymentMY(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(insurance.pricePremiMinMY.replaceAll(".", "").replaceAll(",", ".")) <= 0;
  }

  isErrorMaxInsurancePayment(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(this.replaceComma(insurance.pricePremiMax)) <= 0;
  }

  isErrorMaxInsurancePaymentMY(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(insurance.pricePremiMaxMY.replaceAll(".", "").replaceAll(",", ".")) <= 0;
  }

  isErrorInsuranceCost(index: number) {
    const insurance = this.insurance.configurableInsurance.insuranceTypes[
      index
    ];
    if (insurance.type === "free") {
      return false;
    }
    return Number(this.replaceComma(insurance.cost)) <= 0;
  }

  formatRinggit(value: any) {
    return formatInputMoneyWithDecimal(value);
  }
}
