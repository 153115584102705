
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import { dateToDateString, dateToTime } from "@/app/infrastructures/misc/Utils";
import { AdjustmentPenaltyController } from "@/app/ui/controllers/AdjustmentPenaltyController";
import router from "@/app/ui/router";
import snakeCase from "lodash/snakeCase";

@Options({
  components: {},
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.modalSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      AdjustmentPenaltyController.setOpenModalSuccess(false);
      next();
    }
  }
})
export default class Edit extends Vue {
  isDisabled = true;
  isEditTerminated = false;
  answer = false;
  newPath = "";
  modalSave = false;

  mounted() {
    this.getDetailAdjustment();
  }

  getDetailAdjustment() {
    AdjustmentPenaltyController.getDetailAdjustmentPenalty({
      id: Number(this.$route.params.id)
    });
  }

  get dataDetail() {
    return AdjustmentPenaltyController.adjustmentPenaltyDetail;
  }

  get isLoading() {
    return AdjustmentPenaltyController.isLoading;
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  goBack() {
    this.$router.push(
      `/tarif/configurable-price/adjustment-penalty/${this.$route.params.id}`
    );
  }

  get isValidate() {
    return !!(
      this.dataDetail.configurableSttAdjustment.mapPenaltyAdjustedBy.length >
        0 &&
      this.dataDetail.configurableSttAdjustment.penaltyApplyFor &&
      this.dataDetail.configurablePriceStatus
    );
  }

  get penaltyAdjustedBys() {
    return [
      {
        name: "Internal",
        value: "internal"
      },
      {
        name: "Consolidator Origin",
        value: "consolidator-origin"
      }
    ];
  }

  penaltyApplyList = [
    {
      name: "Pilih penalty apply for",
      value: ""
    },
    {
      name: "POS",
      value: "pos"
    }
  ];

  isOpenPenaltyApply = false;
  onOpenSelectPenaltyApply() {
    this.isOpenPenaltyApply = true;
  }

  onCloseSelectPenaltyApply() {
    this.isOpenPenaltyApply = false;
  }

  openStatusConfigurableNonAktif = false;
  openStatusConfigurableAktif = false;
  onOpenStatusConfigurablePrice() {
    if (this.dataDetail.configurablePriceStatus === "Active") {
      this.openStatusConfigurableNonAktif = true;
      this.openStatusConfigurableAktif = false;
    } else {
      this.openStatusConfigurableNonAktif = false;
      this.openStatusConfigurableAktif = !this.openStatusConfigurableAktif;
      this.dataDetail.configurablePriceStatus = "Inactive";
    }
  }

  onCloseStatusConfigurableNonAktif() {
    this.openStatusConfigurableNonAktif = false;
  }

  onCloseStatusConfigurableAktif() {
    this.openStatusConfigurableAktif = false;
  }

  changeStatusConfigurableNonAktif() {
    this.openStatusConfigurableNonAktif = false;
    this.onCloseStatusConfigurableNonAktif();
    this.dataDetail.configurablePriceStatus = "Inactive";
  }

  changeStatusConfigurableAktif() {
    this.openStatusConfigurableAktif = false;
    this.onCloseStatusConfigurableAktif();
    this.dataDetail.configurablePriceStatus = "Active";
  }

  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  confirmation() {
    return AdjustmentPenaltyController.openModal;
  }

  onOpenConfirmation() {
    AdjustmentPenaltyController.setOpenModal(true);
  }

  onCloseConfirmation() {
    AdjustmentPenaltyController.setOpenModal(false);
  }

  update() {
    const mapAdjustedBy = this.dataDetail.configurableSttAdjustment.mapPenaltyAdjustedBy.map(
      (item: any) => {
        return item.value;
      }
    );
    AdjustmentPenaltyController.updateAdjustmentPenalty({
      configurablePriceId: this.dataDetail.configurablePriceId,
      configurablePriceType: snakeCase(this.dataDetail.configurablePriceType),
      configurablePriceDescription: this.dataDetail
        .configurablePriceDescription,
      configurablePriceStatus: this.dataDetail.configurablePriceStatus.toLowerCase(),
      configurableSttAdjustment: {
        stt_adjustment_id: this.dataDetail.configurableSttAdjustment
          .sttAdjustmentId,
        stt_adjustment_stt_adjusted_by: mapAdjustedBy.join(","),
        stt_adjustment_penalty_apply_for: this.dataDetail
          .configurableSttAdjustment.penaltyApplyFor
      }
    });
  }

  get modalSuccess() {
    return AdjustmentPenaltyController.openModalSuccess;
  }

  onCloseSuccess() {
    router.push(
      `/tarif/configurable-price/adjustment-penalty/${this.$route.params.id}`
    );
  }
}
