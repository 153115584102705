/* eslint-disable @typescript-eslint/camelcase */

export class EditForwardBookingCommissionApiRequest {
  configurablePriceId = 0;
  configurablePriceType = "forward_booking_commission";
  configurablePriceDescription = "";
  configurablePriceStatus = "active";
  configurableForwardBookingCommission = {
    cfbcId: 1,
    cfbcQuantifier: "",
    cfbcCommissionAmount: 0,
    cfbcAppliedTo: "",
    cfbcShipmentPrefix: ""
  };

  constructor(fields?: Partial<EditForwardBookingCommissionApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON(): string {
    return JSON.stringify({
      configurable_price_id: this.configurablePriceId,
      configurable_price_type: this.configurablePriceType,
      configurable_price_description: this.configurablePriceDescription,
      configurable_price_status: this.configurablePriceStatus,
      configurable_forward_booking_commission: {
        cfbc_id: this.configurableForwardBookingCommission.cfbcId,
        cfbc_quantifier: this.configurableForwardBookingCommission
          .cfbcQuantifier,
        cfbc_comission_amount: this.configurableForwardBookingCommission
          .cfbcCommissionAmount,
        cfbc_applied_to: this.configurableForwardBookingCommission
          .cfbcAppliedTo,
        cfbc_shipment_prefix: this.configurableForwardBookingCommission
          .cfbcShipmentPrefix
      }
    });
  }
}
