
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import TableV1 from "@/app/ui/components/table/index.vue";
import {
  checkRolePermission,
  formatDate,
  formatPriceIDR,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { PRICING_CONFIGURABLE_PRICE } from "@/app/infrastructures/misc/RolePermission";
import { ConfigPriceRtsHQController } from "@/app/ui/controllers/ConfigPriceRtsHQController";
import { DetailPriceRtsHQ } from "@/domain/entities/ConfigPriceRtsHQ";

@Options({
  components: {
    DetailLayout,
    TableV1
  }
})
export default class Detail extends Vue {
  get id(): any {
    return Number(this.$route.query.params);
  }

  get createdAt() {
    return formatDate(this.dataDetail.configurablePriceCreatedAt);
  }
  get updatedAt() {
    return formatDate(this.dataDetail.configurablePriceUpdatedAt);
  }
  get isEditAble() {
    return checkRolePermission(PRICING_CONFIGURABLE_PRICE.EDIT);
  }

  mounted() {
    this.fetchDetail();
  }

  fetchDetail() {
    this.getDetailRtshq();
  }

  //Loading
  isLoading = false;
  //Error
  isError = false;
  //Error Cause
  errorCause = "";
  dataDetail = new DetailPriceRtsHQ();
  async getDetailRtshq() {
    this.isLoading = true;
    try {
      this.dataDetail = await ConfigPriceRtsHQController.getDetailConfigPriceRtsHQ(
            Number(this.$route.params.id)
        );
      this.dataDetail.configurablePriceName = "RTSHQ Penalty"
      this.isError = false;
    } catch (error) {
      this.errorCause = parsingErrorResponse(error).type;
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  // route navigatiion
  goBack() {
    this.$router.push("/tarif/configurable-price/rtshq");
  }

  goEdit() {
    this.$router.push(
      `/tarif/configurable-price/rtshq/${this.$route.params.id}/edit`
    );
  }
}
