
import { Vue, Options } from "vue-class-component";
import Checkbox from "primevue/checkbox";
import { ForwardBookingCommissionController } from "@/app/ui/controllers/ForwardBookingCommissionController";

@Options({
  components: {
    Checkbox
  }
})
export default class ForwardBookingCommissionEditSectionAppliedTo extends Vue {
  get detailData() {
    return ForwardBookingCommissionController.forwardBookingCommissionDetail;
  }
  get commissionAppliedTo() {
    if (
      !this.detailData.configurablePriceForwardBookingCommission
        .commissionAppliedTo
    ) {
      return [];
    }
    return this.detailData.configurablePriceForwardBookingCommission.commissionAppliedTo.split(
      ","
    );
  }
  set commissionAppliedTo(newValue: Array<string>) {
    ForwardBookingCommissionController.setCommissionAppliedTo(
      newValue.toString()
    );
  }
  onChangeCommissionAppliedTo() {
    if (this.commissionAppliedTo.includes("shipment")) {
      this.commissionShipmentPrefix = this.availableShipmentPrefix.filter(
        (prefix: string) => !this.disabledShipmentPrefix.includes(prefix)
      );
    } else {
      this.commissionShipmentPrefix = [];
    }
  }

  get commissionShipmentPrefix() {
    if (
      !this.detailData.configurablePriceForwardBookingCommission
        .commissionPrefix
    ) {
      return [];
    }
    return this.detailData.configurablePriceForwardBookingCommission.commissionPrefix.split(
      ","
    );
  }
  set commissionShipmentPrefix(newValue: Array<string>) {
    ForwardBookingCommissionController.setCommissionShipmentPrefix(
      newValue.toString()
    );
    if (newValue.length) {
      if (!this.commissionAppliedTo.includes("shipment")) {
        this.commissionAppliedTo = ["shipment", ...this.commissionAppliedTo];
      }
    } else {
      this.commissionAppliedTo = this.commissionAppliedTo.filter(
        (appliedTo: string) => appliedTo !== "shipment"
      );
    }
  }
  get availableShipmentPrefix() {
    return ForwardBookingCommissionController.availableShipmentPrefix;
  }
  get disabledShipmentPrefix() {
    return ForwardBookingCommissionController.disabledShipmentPrefix;
  }
  isShipmentPrefixDisabled(option: string) {
    return this.disabledShipmentPrefix.includes(option);
  }

  // Toggle Status
  openStatus = false;
  onOpenStatus() {
    if (this.isStatus) {
      this.openStatus = true;
    } else {
      this.changeStatus();
    }
  }
  onCloseStatus() {
    this.openStatus = false;
  }
  get isStatus() {
    return this.detailData.configurablePriceStatus.toLowerCase() === "active";
  }
  changeStatus() {
    if (this.isStatus) {
      this.onCloseStatus();
      this.detailData.configurablePriceStatus = "Inactive";
    } else {
      this.detailData.configurablePriceStatus = "Active";
    }
  }
}
