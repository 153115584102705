export class ConfigPriceRtsHQEndpoint {

    editConfigPriceRtsHQ() {
        return "/horde/v1/configurable";
    }

    getDetailConfigPriceRtsHQ(id: number) {
        return `/horde/v1/configurable/penalty_rtshq/${id}`;
    }
}
