
import { Vue, Options } from "vue-class-component";
import { ForwardBookingCommissionController } from "@/app/ui/controllers/ForwardBookingCommissionController";
import Checkbox from "primevue/checkbox";

@Options({
  components: {
    Checkbox
  }
})
export default class ForwardBookingCommissionDetailSectionAppliedTo extends Vue {
  mounted() {
    ForwardBookingCommissionController.getDetailForwardBookingCommission(
      this.$route.params.id
    );
  }

  get detailData() {
    return ForwardBookingCommissionController.forwardBookingCommissionDetail;
  }
  get commissionAppliedTo() {
    const appliedTo = this.detailData.configurablePriceForwardBookingCommission.commissionAppliedTo.split(
      ","
    );
    const prefixs = this.detailData.configurablePriceForwardBookingCommission.commissionPrefix.split(
      ","
    );

    return [...appliedTo, ...prefixs];
  }
  get availableShipmentPrefix() {
    return ForwardBookingCommissionController.availableShipmentPrefix;
  }
}
