import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inline-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_ForwardBookingCommissionDetailSectionGeneralConfig = _resolveComponent("ForwardBookingCommissionDetailSectionGeneralConfig")!
  const _component_ForwardBookingCommissionDetailSectionCommission = _resolveComponent("ForwardBookingCommissionDetailSectionCommission")!
  const _component_ForwardBookingCommissionDetailSectionAppliedTo = _resolveComponent("ForwardBookingCommissionDetailSectionAppliedTo")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CreateUpdate = _resolveComponent("CreateUpdate")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_DetailLayout, {
      onBack: _ctx.goBack,
      title: "Edit Forward Rate Booking Commission"
    }, {
      "top-right": _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode(_component_lp_button, {
            "text-color": "white",
            customClass: "w-full py-2.5",
            title: "Simpan",
            onClick: _ctx.onOpenSave
          }, null, 8, ["onClick"])
        ])
      ]),
      data: _withCtx(() => [
        _createVNode(_component_ForwardBookingCommissionDetailSectionGeneralConfig),
        _createVNode(_component_ForwardBookingCommissionDetailSectionCommission),
        _createVNode(_component_ForwardBookingCommissionDetailSectionAppliedTo)
      ]),
      "sub-data": _withCtx(() => [
        _createVNode(_component_DataWrapper, { label: "Status Configurable Price" }, {
          default: _withCtx(() => [
            _createVNode(_component_Chips, {
              class: "mt-1.5",
              label: _ctx.detailData.configurablePriceStatus,
              textColor: 
            _ctx.detailData.configurablePriceStatus.toLowerCase() === 'active'
              ? 'green-lp-200'
              : 'red-lp-200'
          ,
              color: 
            _ctx.detailData.configurablePriceStatus.toLowerCase() === 'active'
              ? 'green-lp-300'
              : 'red-300'
          ,
              rounded: ""
            }, null, 8, ["label", "textColor", "color"])
          ]),
          _: 1
        }),
        _createVNode(_component_CreateUpdate, {
          createdAt: _ctx.detailData.configurablePriceCreatedAt,
          createdBy: _ctx.detailData.configurablePriceCreatedBy,
          updatedAt: _ctx.detailData.configurablePriceUpdatedAt,
          updatedBy: _ctx.detailData.configurablePriceUpdatedBy
        }, null, 8, ["createdAt", "createdBy", "updatedAt", "updatedBy"])
      ]),
      _: 1
    }, 8, ["onBack"]),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: _ctx._onEdit,
      onClose: _ctx.onCloseSave,
      title: "Konfirmasi Perubahan Configurable Price",
      message: 
      `Anda yakin akan menyimpan perubahan untuk Detail “${_ctx.detailData.configurablePriceName}“`
    ,
      image: "are-you-sure",
      textSuccess: "Simpan",
      textCancel: "Kembali",
      class: "px-8"
    }, null, 8, ["onSubmit", "onClose", "message"]), [
      [_vShow, _ctx.openModal]
    ])
  ], 64))
}