/* eslint-disable @typescript-eslint/camelcase */
import { DetailInsurance, Insurance } from "@/domain/entities/Insurance";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { InsurancePresenter } from "../presenters/InsurancePresenter";
import { EditInsuranceApiRequest } from "@/data/payload/api/InsuranceApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface InsuranceState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  insurance: DetailInsurance;
  isStatusInsurance: boolean;
  modalSuccess: boolean;
  errorEdit: boolean;
  messageSuccess: string;
  modalSave: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "insurance" })
class InsuranceStore extends VuexModule implements InsuranceState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public insurance = new DetailInsurance();
  public isStatusInsurance = false;
  public modalSuccess = false;
  public errorEdit = false;
  public messageSuccess = "";
  public modalSave = false;

  @Action
  public async getDetailInsurance(params: { id: any }) {
    this.setLoading(true);
    const presenter = container.resolve(InsurancePresenter);
    presenter
      .getDetailInsurance(params.id)
      .then((res: DetailInsurance) => {
        this.setDetailInsurance(res);
        this.setStatusInsurance(
          this.insurance.configPrice.configurablePriceStatus.toLowerCase() ===
            "active"
            ? true
            : false
        );
        this.setError(false);
        this.setErrorcause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorcause(err.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async editInsurance(params: {
    configurablePriceId: number;
    configurablePriceType: string;
    configurablePriceDescription: string;
    configurablePriceStatus: string;
    vendorName: string;
    isApiUrl: string;
    apiUrl: string;
    apiToken: string;
    insurances: Array<any>;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(InsurancePresenter);
    const data = new EditInsuranceApiRequest(
      params.configurablePriceId,
      params.configurablePriceType,
      params.configurablePriceDescription,
      params.configurablePriceStatus,
      params.vendorName,
      params.isApiUrl,
      params.apiUrl,
      params.apiToken,
      params.insurances
    );
    presenter
      .editInsurance(data)
      .then(() => {
        this.setModalSuccess(true);
        this.setErrorEdit(false);
        this.setErrorcause("");
        this.setMessageSuccess(
          `Detail Versi “${this.insurance.configPrice.configurablePriceName}” berhasil diubah`
        );
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Insurance Gagal!", () =>
            this.editInsurance(params)
          )
        );
      })
      .finally(() => {
        this.setLoading(false);
        this.setModalSave(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public handleError() {
    this.setErrorEdit(false);
  }

  @Mutation
  public setModalSave(modal: boolean) {
    this.modalSave = modal;
  }

  @Mutation
  public setModalSuccess(modal: boolean) {
    this.modalSuccess = modal;
  }

  @Mutation
  public setErrorEdit(bool: boolean) {
    this.errorEdit = bool;
  }

  @Mutation
  public setMessageSuccess(str: string) {
    this.messageSuccess = str;
  }

  @Mutation
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public setError(err: boolean) {
    this.isError = err;
  }

  @Mutation
  public setErrorcause(errCause: string) {
    this.errorCause = errCause;
  }

  @Mutation
  public setDetailInsurance(insurance: DetailInsurance) {
    const newData: any = insurance;
    const isOpen = newData.configurableInsurance.insuranceTypes.map(
      (key: Insurance) => ({
        ...key,
        isOpen: false,
        priceGoodsMin: key.priceGoodsMin.toLocaleString("id"),
        priceGoodsMax: key.priceGoodsMax.toLocaleString("id"),
        pricePremiMin: key.pricePremiMin.toLocaleString("id"),
        pricePremiMax: key.pricePremiMax.toLocaleString("id"),
        priceGoodsMinMY: key.priceGoodsMinMY.toLocaleString("id"),
        priceGoodsMaxMY: key.priceGoodsMaxMY.toLocaleString("id"),
        pricePremiMinMY: key.pricePremiMinMY.toLocaleString("id"),
        pricePremiMaxMY: key.pricePremiMaxMY.toLocaleString("id"),
        cost: key.cost.toString().replace(".", ","),
        commisionPos: key.commisionPos.toString().replace(".", ",")
      })
    );
    this.insurance = {
      configPrice: { ...insurance.configPrice },
      configurableInsurance: {
        ...insurance.configurableInsurance,
        insuranceTypes: isOpen
      }
    };
  }

  @Mutation
  public setStatusInsurance(status: boolean) {
    this.isStatusInsurance = status;
  }
}

export const InsuranceController = getModule(InsuranceStore);
